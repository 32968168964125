import { Component, OnInit, EventEmitter, Output, Input, NgZone, ViewChild } from '@angular/core';
import { SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss']
})
export class ProductCarouselComponent implements OnInit {
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input('product') product: any;
  @Input('currency') currency: any;
  public config: SwiperConfigInterface = {};
  constructor(private ngZone:NgZone) { }

  ngOnInit() {
    this.config = {
      observer: true,
      slidesPerView: 5,
      spaceBetween: 0,
      keyboard: false,
      navigation: false,
      pagination: false,
      grabCursor: false,
      loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },


      }
    }
  }
  ngAfterViewInit() {

  }

}



