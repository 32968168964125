import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { config } from "../services/config";
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Login } from '../modals/response';
import { CustomHeaderService } from './custom-header.service';
import { AuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { OMeta } from '../modals/general';
 import { decryptAPI } from './security.provider';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient, 
    private headerService: CustomHeaderService, 
     
    private authService: AuthService,) { }
  signin(form, provider: string = "none"): Observable<Login> {
    const headers = new HttpHeaders(this.headerService.basicHeaders(true))
    // const body = JSON.stringify(form);
    // console.log("body", body);

    let actionUrl;
    if (provider === "none") actionUrl = "auth/login";
    else if (provider.toLowerCase() === "google") actionUrl = "auth/login/google";
    else if (provider.toLowerCase() === "facebook") actionUrl = "auth/login/facebook";

    return this.http.post<string>(config.ApiUrl + actionUrl, form, { headers }).pipe(
      map((data) => {
        return new Login(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);

  }

  signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  resetPasswordRequest(email: string) {
    console.log("emailemail", email);

    const headers = new HttpHeaders(this.headerService.basicHeaders(true))
    return this.http.post<string>(config.ApiUrl + "auth/password/request", { email: email }, { headers }).pipe(
      map((data) => {
        return new OMeta(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  resetPasswordUpdate(form: any): Observable<OMeta> {
    const headers = new HttpHeaders(this.headerService.basicHeaders(true))
    return this.http.post<string>(config.ApiUrl + "auth/password/reset", form, { headers }).pipe(
      map((data) => {
        return new OMeta(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
}
