import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'variants-select',
  templateUrl: './variants-select.component.html',
  styleUrls: ['./variants-select.component.sass']
})
export class VariantsSelectComponent implements OnInit {

  @Input("variant") variant: any;
  @Input("variantDisable") variantDisable: any;
  @Input("type") type: string;
  @Input("selected") selected: any;
  @Input("index") index: number;
  @Output() variantSelect: EventEmitter<any> = new EventEmitter();
  @Output() variantEnableAllSelect: EventEmitter<number> = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  selectedChange(value) {
    this.variantSelect.emit({title:this.type, value:value})
  }
  // selectedEnableAll(value) {
  //   this.variantEnableAllSelect.emit(value)
  // }
}
