import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Settings, AppSettings } from './components/shared/services/color-option.service';
import { Router, NavigationEnd } from '@angular/router';
import * as Fingerprint from 'fingerprintjs'
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  title = 'Looklok';
  public settings: Settings;
  public url: any;
  @ViewChild("dialogAppInstall") dialogAppInstall: TemplateRef<any>;
  constructor(public translate: TranslateService, 
    private spinner: NgxSpinnerService,
    private dialog: MatDialog, 
    public appSettings: AppSettings, 
    private meta:Meta,
    public router: Router) {
    translate.addLangs(['ar', 'en']);
    if (localStorage.getItem('locale')) {
      const browserLang = localStorage.getItem('locale');
      translate.use(browserLang.match(/ar|en/) ? browserLang : 'ar');
    } else {
      localStorage.setItem('locale', 'ar');
      translate.setDefaultLang('ar');
      translate.use("ar");
    }


    this.settings = this.appSettings.settings;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
      }
    })
    if (this.translate.currentLang === "ar") {
      document.body.dir = "rtl";
    }
    else {
      document.body.dir = "ltr";
    }
    this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      if (event.lang === "ar") {
        document.body.dir = "rtl";
        const lang = document.createAttribute('lang');
        lang.value = event.lang
      }
      else {
        document.body.dir = "ltr";
        const lang = document.createAttribute('lang');
        lang.value = event.lang
      }
      this.meta.updateTag({ name: 'description', content: event.translations.titles.description })
    });

  }


  ngOnInit() {
    /** spinner starts on init */
    this.generateFingerPrintId();
    this.generateTokin();
    this.spinner.show();
    
    // setTimeout(() => {
    //   /** spinner ends after 5 seconds */
    //   this.spinner.hide();
    // }, 5000);

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }
ngAfterViewInit(): void {
  if(window.innerWidth < 769) { // It appears in the mobile screen only =< 768
    this.dialog.open(this.dialogAppInstall,{
      width:"100%",
      maxWidth:"100%",
      position: { left: '0', top: "0" },
    });  
  }
}
  generateFingerPrintId() {
    var finger_print_id = localStorage.getItem('finger_print_id');
    if (finger_print_id == null) {
      var fp = new Fingerprint({
        canvas: true,
        ie_activex: true,
        screen_resolution: true
      });
      var uid = fp.get();
      localStorage.setItem('finger_print_id', uid)
    }
  }
  generateTokin() {
    var static_generated_token = localStorage.getItem(
      "static_generated_token"
    );
    if (static_generated_token == null) {
      static_generated_token =
        Date.now().toString() +
        Math.floor(Math.random() * 1000000000).toString();
      console.log(static_generated_token);
      localStorage.setItem("static_generated_token", static_generated_token);
    }
  }
  goToStorInstall(){
    let userAgent = navigator.userAgent || navigator.vendor || window["opera"];
    // if(/windows phone/i.test(userAgent)) {

    // }
    if(/android/i.test(userAgent)) {
      window.location.href = "https://backend.looklok.com/App/looklok.apk";
    }
    if(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/us/app/looklok/id1459121522";
    }
    else window.location.href = "https://backend.looklok.com/App/looklok.apk";
  }
}