import * as CryptoJS from 'crypto-js';
import { config } from './config';


let privateKey:string = config.decryptKey
const enableDecrypt:boolean = true;
export function decryptAPI(data:any) {
    if(enableDecrypt===true) {
        // Laravel creates a JSON to store iv, value and a mac and base64 encodes it.
        // So let's base64 decode the string to get them.
        let encrypted = window.atob(data);
        encrypted = JSON.parse(encrypted);
    
        // IV is base64 encoded in Laravel, expected as word array in cryptojs
        const iv = CryptoJS.enc.Base64.parse(encrypted["iv"]);
    
        // Value (chipher text) is also base64 encoded in Laravel, same in cryptojs
        const value = encrypted["value"];
    
    
        // Key is base64 encoded in Laravel, word array expected in cryptojs
        let key = CryptoJS.enc.Base64.parse(privateKey);
    
        // Decrypt the value, providing the IV. 
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            iv: iv
        });
    
        // CryptoJS returns a word array which can be 
        // converted to string like this
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        // console.log("[[[[[decrypted]]]]",decrypted);
        
        return JSON.parse(decrypted)
    }
    return data;
}