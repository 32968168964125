import { Component, OnInit, ViewChild } from '@angular/core';
import { IConfirm } from 'src/app/modals/request';
import { SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/app/services/base.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { RegisterService } from 'src/app/services/register.service';
import { LoginRegisterComponent } from '../login-register/login-register.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-confirm-register',
  templateUrl: './confirm-register.component.html',
  styleUrls: ['./confirm-register.component.sass']
})
export class ConfirmRegisterComponent implements OnInit {

  confirmForm: IConfirm = {
    email: "",
    code: "",
    type: "",
    device_guid: "",
    finger_print_id: ""
  }
  translateContent = {
    code: "",
  }
  submitting: boolean = false;
  onlangChangeContentSubscription: Subscription;
  @ViewChild("LoginRegisterComponent") loginRegisterComponent: LoginRegisterComponent;
  constructor(private translate: TranslateService, 
    private titleService: Title,
    private authService: AuthServices,
    private router: Router,
    private spinner: NgxSpinnerService,
    private baseService: BaseService,
    private registerServices: RegisterService) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.register_confirm);
      })
      this.setContentLanguage()
      this.onlangChangeContentSubscription = this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
        this.setContentLanguage()
      });
  }

  ngOnInit() {
    this.confirmForm.email = history.state.email
    console.log("history.state.email", history.state.email);
    if (!history.state.email) this.router.navigate(['/'])

  }
  setContentLanguage() {
    if (this.translate.currentLang === "en") {
      this.translateContent.code = "Code";
    }
    else if (this.translate.currentLang === "ar") {
      this.translateContent.code = "الكود";
    }
  }
  confirm(form: HTMLInputElement) {
    console.log("form", form);
    this.confirmForm.code = form.value["code"];
    this.confirmForm.type = "email";
    this.confirmForm.device_guid = localStorage.getItem('static_generated_token');
    this.confirmForm.finger_print_id = localStorage.getItem('finger_print_id');
    this.spinner.show();
    this.submitting = true;
    this.registerServices.confirm(this.confirmForm).subscribe(
      (result) => {
        console.log("result is ", result);
        this.spinner.hide();
        this.submitting = false;
        this.authService.setAuthenticatedUser({ token_info: result.tokenInfo, user_info: result.userInfo, meta: result.meta })
        this.router.navigate(['/complate-register'])
        this.baseService.alert(result.meta.message, "success")
      },
      (error) => {
        console.log("error is ", error);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  resendCode() {
    this.spinner.show();
    this.submitting = true;
    this.registerServices.resendCode({ email: this.confirmForm.email, type: "email" }).subscribe(
      (result) => {
        console.log("result is ", result.message);
        console.log("result is ", result);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(result.message, "success")
      },
      (error) => {
        console.log("error is ", error);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  canEnter(event, valid) {
    if (this.submitting || !valid) {
      event.preventDefault();
      return false;
    }
  }
  ngOnDestroy(): void {
    if (this.onlangChangeContentSubscription) {
      this.onlangChangeContentSubscription.unsubscribe()
    }
  }
}
