import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, RouterModule } from "@angular/router";
import { AppSettings } from './services/color-option.service'

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatListModule } from '@angular/material/list';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { HeaderTwoComponent } from './header-two/header-two.component';
import { FooterTwoComponent } from './footer-two/footer-two.component';
import { MatBadgeModule } from '@angular/material/badge';
import { ShoppingWidgetsTwoComponent } from './shopping-widgets-two/shopping-widgets-two.component';
import { CategoryMenuComponent } from '../category-menu/category-menu.component';
import { MatTreeModule } from '@angular/material/tree';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationComponent } from '../pagination/pagination.component';
import { ProductComponent } from '../shop/products/product/product.component';
import { CategoriesComponent } from '../shop/widgets/categories/categories.component';
import { CustomFormsModule } from 'ngx-custom-validators';

import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { ProductCarouselComponent } from '../shop/product-carousel/product-carousel.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FormsModule } from '@angular/forms';
import { CustomRouteReuseStrategy } from 'src/app/custom-route-reuse-strategy';
import { AgmCoreModule } from '@agm/core';
import { SetHeightDirective } from 'src/app/directives/set-height.directive';

@NgModule({
  declarations: [
    HeaderTwoComponent,
    FooterTwoComponent,
    ShoppingWidgetsTwoComponent,
    PaginationComponent,
    ProductComponent,
    CategoriesComponent,
    CategoryMenuComponent,
    ProductCarouselComponent,
    SetHeightDirective
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatListModule,
    MatSliderModule,
    MatExpansionModule,
    MatBadgeModule,
    MatMenuModule,
    MatTableModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,
    FlexLayoutModule,
    MatTreeModule,
    CustomFormsModule,
    TranslateModule,
    MatPasswordStrengthModule,
    SwiperModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCI8-2mH3gjrF9HjLVBI-xXRtUN5Q9Eg_0',
      libraries: ["places"]
    }),
  ],
  exports: [
    // FormsModule,
    TranslateModule,
    MatButtonModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatListModule,
    MatExpansionModule,
    MatMenuModule,
    MatTableModule,
    MatSliderModule,
    MatRadioModule,
    MatDialogModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatSidenavModule,
    MatSelectModule,
    MatTabsModule,
    MatDividerModule,
    MatCardModule,
    FlexLayoutModule,
    HeaderTwoComponent,
    FooterTwoComponent,
    PaginationComponent,
    ProductComponent,
    CategoriesComponent,
    CustomFormsModule,
    ShoppingWidgetsTwoComponent,
    TranslateModule,
    MatPasswordStrengthModule,
    CategoryMenuComponent,
    ProductCarouselComponent,
    SwiperModule,
    AgmCoreModule,
    SetHeightDirective
  ],
  providers: [
    AppSettings,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy 
    }
  ]
})
export class SharedModule { }
