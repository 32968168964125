import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { CategorayService } from 'src/app/services/categoray.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

/**
 * Food data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface Category {
  id: number;
  main_image: string;
  parent_id: number;
  sort: number;
  title: string;
}
interface CategoryNode {
  category: Category;
  children?: CategoryNode[];
}

// const TREE_DATA: CategoryNode[] = [
//   {
//     name: 'Fruit',
//     children: [
//       { name: 'Apple' },
//       { name: 'Banana' },
//       { name: 'Fruit loops' },
//     ]
//   },
//   {
//     name: 'Vegetables',
//     children: [
//       {
//         name: 'Green',
//         children: [
//           { name: 'Broccoli' },
//           { name: 'Brussels sprouts' },
//         ]
//       }, {
//         name: 'Orange',
//         children: [
//           { name: 'Pumpkins' },
//           { name: 'Carrots' },
//         ]
//       },
//     ]
//   },
// ];


@Component({
  selector: 'app-category-menu',
  templateUrl: './category-menu.component.html',
  styleUrls: ['./category-menu.component.sass']
})

export class CategoryMenuComponent implements OnInit {

  treeControl = new NestedTreeControl<CategoryNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<CategoryNode>();
  categories: any[] = [];
  constructor(private categoryService: CategorayService, private translate: TranslateService) {
    // this.dataSource.data = TREE_DATA;
  }
  ngOnInit(): void {
    this.dataSource.data = this.categories;
    this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.getCategories()
    });
  }

  hasChild = (_: number, node: CategoryNode) => !!node.children && node.children.length > 0;

  getCategories() {
    this.categoryService.getCategories(undefined).subscribe(
      (result) => {
        console.log("result.category", result.categories);
        this.categories = result.categories;
        this.dataSource.data = this.categories;
        console.log("this.treeControl.dataNodes",)
        this.treeControl.expand(this.dataSource.data[0]); // to auto open first items of data
      },
      (error) => {
      }
    );
  }
}
