import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input("max") max = 5
  @Output() currentPage: EventEmitter<Number> = new EventEmitter<Number>();
  currentPageNum = 1;
  items: any = {}
  constructor(    private route: ActivatedRoute,
    private router: Router,
    private location:Location
   ) { 
     this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    }

  ngOnInit(): void {
    console.log("ngOnInit PAGINATION");
    

  }
  ngOnChanges(changes: SimpleChanges) {
    // this.setPage(this.currentPageNum)
    this.items = this.getPager(this.max, this.currentPageNum)
    this.route.queryParamMap.subscribe((params) => {
      if (params.get("page") !== null) {
        this.currentPageNum = Number(params.get("page"));
        this.setPage(this.currentPageNum);
      }
      else  this.setPage(this.currentPageNum);
    });
  }
  // get items() {
  //   let array = [];
  //   for (let i = 0; i < Number(this.max); i++)
  //     array.push(i + 1)
  //   return array;
  // }
  setPage(page) {
    console.log("this.max", this.max);
    console.log("page", page);

    // this.items = this.getPager(this.max, page)
    if (page > 0 && page <= this.max) {
      this.currentPageNum = page;
      this.currentPage.emit(this.currentPageNum);
      console.log("this.router.url ==========> ",this.route);     
      window.scrollTo(0,0);

    }

  }
  goToPage(page) {
    
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: page
      },
      queryParamsHandling: 'merge',
      // preserve the existing query params in the route
      // skipLocationChange: false,
      // replaceUrl:true
      // do not trigger navigation
    });
    
  }
  getPager(totalItems: number, currentPage: number = 1) {
    // calculate total pages
    let totalPages = totalItems - 1
    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    }
    else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5;
      } else if (currentPage + 1 >= totalPages) {
        startPage = totalPages - 4;
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // create an array of pages to ng-repeat in the pager control
    let pages = this.range(startPage, endPage + 1);

    // return object with all pager properties required by the view
    return {
      pages: pages
    };
  }
  range(start, end) {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  }
}
