import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { AuthServices } from 'src/app/services/auth.service';
import { Subscription } from 'rxjs';
import { CheckoutService } from 'src/app/services/checkout.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RegisterService } from 'src/app/services/register.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.sass']
})
export class MyProfileComponent implements OnInit, OnDestroy {
  userInfo$;
  translateContent = {
    name: "Name",
    address: "Address Line",
    postalCode: "Postal Code",
    phone: "Phone",
    code: "Code",
    oldpassword: "Old Password",
    newpassword: "New Password",
    confirmpassword: "Confirm Password",
  }
  form = {
    name: "",
    email: "",
    accountType: "",
    address: "",
    town: {
      country: { state_key: undefined }
    },
    postal_code: "",
    phone: ""
  }
  readonlyForm = {
    name: true,
    address: true,
    town: true,
    postcode: true,
    phone: true,
    save: true,
    image: true,
    imageClose: true,
  }
  image;
  oldImage = "";
  imageFile;
  mobNumberPattern = "";
  hintMobNumberPattern = "";
  userInfoSubscription: Subscription
  cities: any[];
  cityId: number;
  oldUser: any;
  uploadForm: FormGroup;
  @ViewChild("dialogImagePreview") dialogImagePreview: TemplateRef<any>;
  @ViewChild("dialogPasswordChange") dialogPasswordChange: TemplateRef<any>;
  hideOld: boolean = true;
  hideNew: boolean = true;
  hideConfirm: boolean = true;
  submitting: boolean = false;
  onlangChangeContentSubscription: Subscription;
  phoneNumberCode:string = "";
  fullPhoneNumber:string = "";
  constructor(private authService: AuthServices,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private checkoutService: CheckoutService,
    private registerService: RegisterService,
    private translate: TranslateService,
    private _snackBar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.myprofile);
      })
      this.userInfo$ = this.authService.isAuth;
      this.setContentLanguage()
      this.onlangChangeContentSubscription = this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
        this.setContentLanguage()
      });

  }

  ngOnInit(): void {
    this.getUserInfo();
    this.getAllCities();
    this.uploadForm = this.formBuilder.group({
      profileImage: ['']
    });
  }
  setContentLanguage() {
    if (this.translate.currentLang === "en") {
      this.translateContent.name = "Name";
      this.translateContent.address = "Address Line";
      this.translateContent.postalCode = "Postal Code";
      this.translateContent.phone = "Phone Number";
      this.translateContent.code = "Code";
      this.translateContent.oldpassword = "Old Password";
      this.translateContent.newpassword = "New Password";
      this.translateContent.confirmpassword = "Confirm Password";
    }
    else if (this.translate.currentLang === "ar") {
      this.translateContent.name = "الاسم";
      this.translateContent.address = "العنوان";
      this.translateContent.postalCode = "الكود البريدي";
      this.translateContent.phone = "رقم الهاتف";
      this.translateContent.code = "الكود";
      this.translateContent.oldpassword = "كلمة المرور القديمة";
      this.translateContent.newpassword = "كلمة المرور الجديدة";
      this.translateContent.confirmpassword = "تأكيد كلمة المرور";
    }
  }
  edit() {
    this.readonlyForm = {
      name: false,
      address: false,
      town: false,
      postcode: false,
      phone: false,
      save: false,
      image: false,
      imageClose: true,
    }
    this.image = this.oldImage;
    this.imageFile = null;
  }
  getUserInfo() {
    this.userInfoSubscription = this.userInfo$.subscribe(user => {
      console.log("userProfile", user?.user_info)
      this.oldUser = user;
      this.form.name = user?.user_info?.name;
      this.form.address = user?.user_info?.address;
      this.form.email = user?.user_info?.email;
      this.fullPhoneNumber = user?.user_info?.phone;
      this.form.postal_code = user?.user_info?.postal_code;
      this.cityId = user?.user_info?.city?.id
      this.oldImage = user?.user_info?.image;
      this.image = this.oldImage;
      if (user?.user_info?.provider_name === null)
        this.form.accountType = "Email Account"
      else
        this.form.accountType = user?.user_info?.provider_name
    });

  }
  updateUserInfo() {
    let formData: FormData = new FormData();
    if (this.imageFile) {
      formData.append('image', this.imageFile, this.imageFile.name);
    }
    formData.append('phone', this.phoneNumberCode+this.form.phone);
    formData.append('city_id', this.form.town["id"]);
    if (this.form.postal_code) {
      formData.append('postal_code', this.form.postal_code);
    }
    formData.append('name', this.form.name);
    formData.append('address', this.form.address);
    console.log("this.uploadForm.get('profileImage').value", this.uploadForm.get('profileImage').value.name);

    this.spinner.show();
    this.authService.updateUserInfo(formData).subscribe(
      (result) => {
        console.log("result.updateUserInfo", result.user);
        this.authService.setAuthenticatedUser(this.oldUser, result.user)
        this.spinner.hide();
        this.readonlyForm = {
          name: true,
          address: true,
          town: true,
          postcode: true,
          phone: true,
          save: true,
          image: true,
          imageClose: true,
        }
        this.imageFile = null;
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  getAllCities() {
    this.spinner.show();
    this.checkoutService.getAllCities().subscribe(
      (result) => {
        console.log("result.getAllCities", result);
        this.cities = result.data;
        this.form.town = this.cities.find(item => item.id === this.cityId);
        let partOne: string
        let key: string = this.form.town?.country?.state_key
        this.phoneNumberCode = key;
        this.form.phone = this.fullPhoneNumber.slice(key.length);
        if (this.form.town?.country?.state_key) {
          partOne = "^((\\" + key + ")|00" + key.slice(1) + ")";
          this.hintMobNumberPattern = key + "********"
        }
        else {
          partOne = "^(0)"
          this.hintMobNumberPattern = "0********"
        }
        this.mobNumberPattern = "[0-9]*"
        console.log("this.mobNumberPattern ", this.mobNumberPattern);
        console.log("this.form.town", this.form.town);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message);
      }
    );
  }
  onImageFileSelect(event) {
    let fileList: FileList = event.target.files;
    console.log("fileList[0]", event);

    if (fileList.length > 0) {
      this.imageFile = fileList[0];
      var reader = new FileReader();
      const self = this;
      reader.readAsDataURL(this.imageFile);
      reader.onload = (_event) => {
        self.image = reader.result;
      }
      // this.dialog.open(this.dialogImagePreview, {
      //   width: '50%',
      // });
      document.querySelector(".cdk-global-overlay-wrapper").setAttribute("dir", document.body.dir)
      this.readonlyForm.image = true;
      this.readonlyForm.imageClose = false;
    }

  }
  closeImage() {
    this.readonlyForm.image = false;
    this.readonlyForm.imageClose = true;
    this.image = this.oldImage;
    this.imageFile = null;
  }
  changeCity() {
    let partOne: string
    let key: string = this.form.town?.country?.state_key;
    this.phoneNumberCode = key;
    if (this.form.town?.country?.state_key) {
      partOne = "^((\\)|00" + key.slice(1) + ")";
      this.hintMobNumberPattern = key + "********"
    }
    else {
      partOne = "^(0)"
      this.hintMobNumberPattern = "0********"
    }
    this.mobNumberPattern = "[0-9]*"
    console.log("this.mobNumberPattern ", this.mobNumberPattern);
  }
  OpenDialogChangePassword() {
    this.dialog.open(this.dialogPasswordChange);
    document.querySelector(".cdk-global-overlay-wrapper").setAttribute("dir", document.body.dir)
  }
  changePassword(oldpassword, newpassword) {
    this.submitting = true;
    this.spinner.show();
    this.registerService.changePassword({ old_password: oldpassword, password: newpassword }).subscribe(
      (result) => {
        this.snackBarOpen(result?.meta?.message);
        this.authService.setAuthenticatedUser({ token_info: result.tokenInfo, user_info: result.userInfo, meta: result.meta })
        this.dialog.closeAll();
        console.log("result.changePassword", result);
        // this.authService.resetAuthenticated();
        // this.router.navigate(['/login-register'])
        this.spinner.hide();
        this.submitting = false;
      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message);
        this.submitting = false;
      }
    );
  }
  snackBarOpen(msg: string) {
    this._snackBar.open(msg, 'Cancel', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: "top",
    });
  }
  canEnter(event, valid) {
    if (this.submitting || !valid) {
      event.preventDefault();
      return false;
    }
  }
  ngOnDestroy(): void {
    this.userInfoSubscription.unsubscribe();
    this.onlangChangeContentSubscription.unsubscribe();
  }
}
