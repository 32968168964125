import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OCartOption } from 'src/app/modals/general';
import { AuthServices } from 'src/app/services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.sass']
})

export class CartComponent implements OnInit {
  myCart$;
  optionsMyCart: OCartOption[];
  totalPrice: number = 0;
  userInfo$;
  prices: object = {};
  msgWarningUpdatePrices: string = "";
  @ViewChild("dialogUpdatePrice") dialogUpdatePrice: TemplateRef<any>;
  constructor(public cartService: CartService,
    public checkoutService: CheckoutService,
    public spinner: NgxSpinnerService,
    public authService: AuthServices,
    public _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public titleService: Title,
    public translate: TranslateService,
    public router: Router) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.mycart);
      })
      this.userInfo$ = this.authService.isAuth;
      this.myCart$ = this.cartService.myCart;
    }

  ngOnInit() {
    this.spinner.hide();
    this.cartService.getMyCartSubscribe()

  }
  updateOptionCart(option_id: number, quantity: number) {
    this.spinner.show();
    this.cartService.updateOptionCart(option_id, quantity).subscribe(
      (result) => {
        console.log("result.updateOptionCart", result);
        this.cartService.setMyCartInArrayObservable(result);
        console.log("this.myCart$", this.myCart$);
        this.spinner.hide();
        this.snackBarOpen(result?.meta?.message);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message);
      }
    );
  }
  updatePricesCart(cart_id: number) {
    this.spinner.show();
    this.cartService.updatePricesCart(cart_id).subscribe(
      (result) => {
        console.log("result.updatePricesCart", result);
        this.cartService.setMyCartInArrayObservable(result);
        console.log("this.myCart$", this.myCart$);
        this.spinner.hide();
        this.dialog.closeAll();
      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message);
      }
    );
  }
  // Remove cart items
  removeItem(optionId: number) {
    this.spinner.show();
    this.cartService.deleteCart(optionId).subscribe(
      (result) => {
        this.spinner.hide();
        this.cartService.getMyCartSubscribe()
        this.snackBarOpen(result?.message)
      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message)
      }
    );
  }


  // Increase Product Quantity
  increment(optionId: any, quantity: number, maxQuantity: number) {
    console.log("increment()=>quantity", quantity);

    if (quantity >= maxQuantity) {
      this.snackBarOpen('You cannot add more, there is no more quantity');
    }
    else {
      const newQuantity = quantity + 1
      this.updateOptionCart(optionId, newQuantity);
    }
  }

  // Decrease Product Quantity
  decrement(optionId: number, quantity: number, maxQuantity: number) {
    console.log("decrement()=>quantity", quantity);
    if (quantity > maxQuantity) {
      this.snackBarOpen('You cannot add more, there is no more quantity');
    }
    else {
      if (quantity > 1) {
        const newQuantity = quantity - 1
        this.updateOptionCart(optionId, newQuantity);
      }
    }
  }
  checkout() {
    // this.dialog.open(this.dialogUpdatePrice);
    // this.updatePricesCart(cartId);
    this.spinner.show();
    this.checkoutService.checkout().subscribe(
      (result) => {
        console.log("result.checkout", result);
        this.spinner.hide();
        this.router.navigate(['/checkout'])
      },
      (error) => {
        console.log("error.checkout", error);
        if (error.meta.statusNumber === 400) {
          this.msgWarningUpdatePrices = error.meta.message;
          this.dialog.open(this.dialogUpdatePrice);
          document.querySelector(".cdk-global-overlay-wrapper").setAttribute("dir", document.body.dir)
        }
        else this.snackBarOpen(error?.meta?.message);

        this.spinner.hide();
      }
    );
  }
  snackBarOpen(msg: string) {
    this._snackBar.open(msg, 'Cancel', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: "top",
    });
  }

}
