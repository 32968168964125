import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { config } from './config';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { OMeta } from '../modals/general';
import { CustomHeaderService } from './custom-header.service';
import { Login } from '../modals/response';
 import { decryptAPI } from './security.provider';


@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  constructor(private http: HttpClient, 
     
    private headerService: CustomHeaderService) { }
  signup(form: any): Observable<OMeta> {
    const headers = new HttpHeaders(this.headerService.basicHeaders(true))
    // const body = JSON.stringify(form);
    return this.http.post<string>(config.ApiUrl + "auth/register", form, { headers }).pipe(
      map((data) => {
        return new OMeta(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  confirm(form: any): Observable<Login> {
    const headers = new HttpHeaders(this.headerService.basicHeaders(true))
    // const body = JSON.stringify(form);
    return this.http.post<string>(config.ApiUrl + "auth/confirm", form, { headers }).pipe(
      map((data) => {
        return new Login(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  resendCode(form: any): Observable<OMeta> {
    const headers = new HttpHeaders(this.headerService.basicHeaders(true))
    // const body = JSON.stringify(form);
    return this.http.post<string>(config.ApiUrl + "auth/resend/code", form, { headers }).pipe(
      map((data) => {
        return new OMeta(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  changePassword(form: any): Observable<Login> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    // const body = JSON.stringify(form);
    return this.http.post<string>(config.ApiUrl + "auth/password/change", form, { headers }).pipe(
      map((data) => {
        return new Login(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
}
