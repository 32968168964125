import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategorayService } from 'src/app/services/categoray.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-view-by-category',
  templateUrl: './products-view-by-category.component.html',
  styleUrls: ['./products-view-by-category.component.scss']
})
export class ProductsViewByCategoryComponent implements OnInit {
  public sidenavOpen: boolean = true;
  public animation: any;   // Animation
  public sortByOrder: string = '';   // sorting
  // public page:any;
  // public tagsFilters: any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 20;
  // public colorFilters: ColorFilter[] = [];


  // public colors: any[] = [];
/**For Products Viewer Style**/
public my_row:string;
public myCol_md_60:string;
public myCol_md_40:string;
public myImg:string;
public noMovement: boolean=false;

/**For Products Viewer Style End**/

  category: any[] = [];
  tags: any[] = [];

  private itemsSubject;
  items$: Observable<Array<any>>;
  filter = "price";
  dir = "asc";
  subcategory_id;
  category_id;
  tag_ids: any[] = [];
  price: any[] = [];
  page = 1;
  total_pages;
  limit = 20;
  submiting: boolean = false;
  categoryName: string = "";
  binnerImage: string = ""
  currency: string = "";
  userInfo$: Observable<any>;
  constructor(private productService: ProductService,
    private categoryService: CategorayService,
    private authService: AuthServices,
    private route: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.categories);
      })
      this.userInfo$ = this.authService.isAuth;
      this.route.params.subscribe(
        (params: Params) => {
          const category = params['category'];
          console.log("category", category);

          if (category !== "all") {
            this.category_id = Number(category);
            this.onChangeSorting("most_view,none")
          }
        }
      )
  }
  ngOnInit() {
    this.onChangeSorting("most_view,none")
    this.getCategory();
    this.itemsSubject = new BehaviorSubject<Array<any>>([]);
    this.items$ = this.itemsSubject.asObservable();
    console.log("category_id", this.category_id);
    this.itemsSubject.next([]);


  }
  hideMe()
  {
     document.getElementById('myShop').style.display="none!important";
  }
  getProduct() {
    this.submiting = true;
    this.spinner.show();
    this.productService.getProduct(this.filter, this.subcategory_id, this.category_id,
      "none", undefined, this.page.toString(), this.limit.toString(),undefined,this.dir).subscribe(
        (result) => {
  
          this.submiting = false;
          this.itemsSubject.next(result.product.items);
          console.log("this.items$", this.items$);

          console.log("result.product", result.product);
          this.total_pages = result.pagination.total_pages;
          this.page = result.pagination.page;
          this.spinner.hide();
          // if (this.authService.isLogined) {
          //   this.userInfo$.subscribe(user =>
          //     this.currency = user.user_info?.main_currency
          //   )
          // }
          // else {
            this.currency = result?.meta?.main_currency;
          // }
        },
        (error) => {
          this.submiting = false;
        }
      );

  }

  changePage(page) {
    this.page = page;
    this.getProduct();
  }
  // sorting
  public onChangeSorting(val) {
    const value = val.split(',')
    console.log("typeof val", typeof value);
    console.log("val", value);
    this.filter = value[0];
    this.dir = value[1]
    this.getProduct();
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }


  // Update price filter
  public updatePriceFilters(price: any) {
    if (this.submiting === false) {
      console.log("price", price);
      let p = []
      p.push(price.priceFrom);
      p.push(price.priceTo)
      this.price = p
      this.getProduct()
    }
  }

  changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
    if(viewCol=='100')
    {
      this.my_row="myRow";
      this.myCol_md_60="myCol-md-60";
      this.myCol_md_40="myCol-md-40";
      this.myImg="myImg";
      this.noMovement=true;

    }
    else{
      this.my_row=" ";
      this.myCol_md_60=" ";
      this.myCol_md_40=" ";
      this.myImg=" ";
      this.noMovement=false;

    }
  
  }


  getCategory() {
    this.categoryService.getCategories(this.category_id).subscribe(
      (result) => {
        console.log("result.category page", result.categories);
        this.category = result.categories;
        this.categoryName = result?.categories[0]?.category?.title
        this.titleService.setTitle(this.titleService.getTitle()+" | "+this.categoryName);
        this.binnerImage = result?.categories[0]?.category?.banner
        console.log("this.binnerImage", this.binnerImage);

      },
      (error) => {
      }
    );
  }
  updateCategoryFilters(id) {
    console.log("updateCategoryFilters(id)", id);
    this.category_id = id;
    this.subcategory_id = undefined;
    this.getProduct();

  }
  updateSubcategoryFilters(id) {
    console.log("updateSubcategoryFilters(id)", id);
    this.subcategory_id = id;
    this.category_id = undefined
    this.getProduct();
  }



  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }


}
