import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthServices } from 'src/app/services/auth.service';
import { BaseService } from 'src/app/services/base.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {

  public sidenavMenuItems: Array<any>;

 
  public currency: any;
  public flags = [
    { name: 'English', short: 'en' },
    { name: 'العربية', short: 'ar' },

  ]
  public flag: any;


  indexProduct: number;

  public banners = [];


  public url: any;

  userInfo$;
  user
  userInfoSubscribe: Subscription;
  logoutSubscribe: Subscription;
  routerSubscription: Subscription
  @ViewChild('start') sidenav: MatSidenav;
  constructor(public router: Router,
    private authService: AuthServices,
    private baseService: BaseService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.sidenav.close();
        this.url = event.url;
      }
    })
    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return true;
    // };
    // this.routerSubscription = this.router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     // Trick the Router into believing it's last link wasn't previously loaded
    //     this.router.navigated = false;
    //   }
    // });

    this.userInfo$ = this.authService.isAuth;
    // console.log("this.userInfo", this.authService.currentUser);
    this.userInfoSubscribe = this.userInfo$.subscribe(user => {
      if (user) {
        this.user = user
      }
      else {
        this.user = this.authService.currentUser
      }
      console.log("this.userInfo", this.user);
    });

  }
  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {
    // this.currency = this.currencies[0];
    this.flag = this.flags.filter(item => item.short === this.translate.currentLang)[0];

    this.authService.isAuth.subscribe(data => {
      console.log("data", data);

    })

  }


  public changeLang(flag) {
    console.log("URL", this.url);

    this.flag = flag;
    if (flag.short === "ar") {
      localStorage.setItem('locale', "ar");
      this.translate.use('ar');
      console.log("select lang", flag);

    }
    else if (flag.short === "en") {
      localStorage.setItem('locale', "en");
      this.translate.use('en');
      console.log("select lang", flag);
    }
    // this.router.navigate([this.url])
    window.location.reload()
    // this.router.navigateByUrl(this.url);

  }
  logout() {
    const device_guid = localStorage.getItem('static_generated_token');
    console.log("device_guid", device_guid);

    this.spinner.show()
    this.logoutSubscribe = this.authService.logout(device_guid).subscribe(
      (result) => {
        this.authService.resetAuthenticated();
        this.authService.logOutSocial();
        this.router.navigate(['/login-register'])
        this.spinner.hide();
        this.baseService.alert(result.meta.message, "success");
      },
      (error) => {
        this.spinner.hide();
        this.baseService.alert(error?.meta?.message, "error");
      }
    );
  }
}
