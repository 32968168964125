import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';
import { AuthGuardService } from 'src/app/services/auth-guard.service';
import { ProductsViewByCategoryComponent } from './products/products-view-by-category/products-view-by-category.component';
import { ProductsViewBySearchComponent } from './products/products-view-by-search/products-view-by-search.component';
import { CollectionViewComponent } from './products/collection-view/collection-view.component';
import { ProductsViewByCollectionComponent } from './products/products-view-by-collection/products-view-by-collection.component';


// Routes
const routes: Routes = [
  { path: 'products/:category', component: ProductLeftSidebarComponent,
    data: {
      reuseComponent: true
    }
  },
  { path: 'products-by-category/:category', component: ProductsViewByCategoryComponent ,
    data: {
      reuseComponent: true
    }
  },
  { path: 'products-by-search/:search', component: ProductsViewBySearchComponent,
    data: {
      reuseComponent: true
    }
  },
  { path: 'product/:id', component: ProductDetailsComponent ,
    // data: {
    //   reuseComponent: true
    // }
  },
  { path: 'collection', component: CollectionViewComponent ,
    data: {
      reuseComponent: true
    }
  },
  { path: 'collection/:id', component: ProductsViewByCollectionComponent,
    data: {
      reuseComponent: true
    }
  }

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShopRoutingModule { }
