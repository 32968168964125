import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHeaderService } from './custom-header.service';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { config } from './config';
import { map, catchError } from 'rxjs/operators';
import { MyCart, Price } from '../modals/response';
import { OMeta } from '../modals/general';
 import { decryptAPI } from './security.provider';


@Injectable({
  providedIn: 'root'
})
export class CartService {
  public myCart = new BehaviorSubject<any>(null);
  
  constructor(private http: HttpClient,
     
    private headerService: CustomHeaderService) { }

  addCart(option_id: number, quantity: number, price: number): Observable<any> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    // const body = { option_id: option_id, quantity: quantity, price: price }
    const body = { option_id: option_id, quantity: quantity }

    return this.http.post(config.ApiUrl + "cart", body, { headers }).pipe(
      map((data) => {
        return new OMeta(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  deleteCart(option_id: number): Observable<OMeta> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    const params = new HttpParams().set("option_id", option_id.toString());
    return this.http.delete<string>(config.ApiUrl + "cart/option/remove", { params, headers }).pipe(
      map((data) => {
        return new OMeta(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  updatePricesCart(cart_id: number): Observable<MyCart> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.post<string>(config.ApiUrl + "cart/" + cart_id + "/update", null, { headers: headers }).pipe(
      map((data) => {
        return new MyCart(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  updateOptionCart(option_id: number, quantity: number): Observable<MyCart> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    const body = { option_id: option_id, quantity: quantity }
    return this.http.post<string>(config.ApiUrl + "cart/option/update", body, { headers }).pipe(
      map((data) => {
        return new MyCart(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  };
  getMyCart(): Observable<MyCart> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.get<string>(config.ApiUrl + "cart/my-cart", { headers }).pipe(
      map((data) => {
        return new MyCart(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  // getPrice(optionId: number, quantity: number): Observable<Price> {
  //   const headers = new HttpHeaders(this.headerService.basicHeaders())
  //   const params = new HttpParams().set("quantity", quantity.toString());
  //   return this.http.get<Price>(config.ApiUrl + "product-option/" + optionId + "/price", { params, headers }).pipe(
  //     map((data) => {
  //       return new Price(data);
  //     }), catchError(error => {
  //       return throwError(decryptAPI(error));
  //     })
  //   )
  // }

  // to set items of my cart in BehaviorSubject
  setMyCartInArrayObservable(items: any) {
    this.myCart.next(items);
  }
  getMyCartSubscribe() {
    this.getMyCart().subscribe(
      (result) => {
        this.setMyCartInArrayObservable(result);
        console.log("result.getMyCart", result);
      },
      (error) => {
      }
    );

  }
}
