import { Component, OnInit, ViewChild } from '@angular/core';
import { IConfirm, IRsetPassword } from 'src/app/modals/request';
import { SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/app/services/base.service';
import { TranslateService } from '@ngx-translate/core';
import { RegisterService } from 'src/app/services/register.service';
import { LoginRegisterComponent } from '../login-register/login-register.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.sass']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: IRsetPassword = {
    email: "",
    code: "",
    password: "",
  }
  translateContent = {
    code: "",
    password: "",
  }
  submitting: boolean = false;
  @ViewChild("LoginRegisterComponent") loginRegisterComponent: LoginRegisterComponent;
  constructor(private translate: TranslateService, 
    private titleService: Title,
    private loginServices: LoginService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private baseService: BaseService,) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.reset_password);
      })
      if (translate.currentLang === "en") {
        this.translateContent.code = "Code";
        this.translateContent.password = "Password";
      }
      else if (translate.currentLang === "ar") {
        this.translateContent.code = "الكود";
        this.translateContent.password = "كلمة المرور";
      }
  }

  ngOnInit() {
    this.resetForm.email = history.state.email
    console.log("history.state.email", history.state.email);
    if (!history.state.email) this.router.navigate(['/'])

  }

  resetPassword(form: HTMLInputElement) {
    console.log("form", form);
    this.resetForm.code = form.value["code"];
    this.resetForm.password = form.value["password"];
    this.spinner.show();
    this.submitting = true;
    this.loginServices.resetPasswordUpdate(this.resetForm).subscribe(
      (result) => {
        console.log("result is ", result.message);
        console.log("result is ", result);
        this.spinner.hide();
        this.submitting = false;
        this.router.navigate(['/login-register'])
        this.baseService.alert(result.message, "success")
      },
      (error) => {
        console.log("error is ", error);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  resendCode() {
    this.spinner.show();
    this.submitting = true;
    this.loginServices.resetPasswordRequest(this.resetForm.email).subscribe(
      (result) => {
        console.log("result is ", result.message);
        console.log("result is ", result);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(result.message, "success")
      },
      (error) => {
        console.log("error is ", error);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  canEnter(event, valid) {
    if (this.submitting || !valid) {
      event.preventDefault();
      return false;
    }
  }
  ngOnDestroy(): void {

  }
}
