import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategorayService } from 'src/app/services/categoray.service';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-collection-view',
  templateUrl: './collection-view.component.html',
  styleUrls: ['./collection-view.component.sass']
})
export class CollectionViewComponent implements OnInit {
  public sidenavOpen: boolean = true;
  public animation: any;   // Animation
  public sortByOrder: string = '';   // sorting
  // public page:any;
  // public tagsFilters: any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  private itemsSubject;
  items$: Observable<Array<any>>;
  page = 1;
  total_pages;
  limit = 20;
  submiting: boolean = false;
  constructor(private productService: ProductService, 
    private titleService: Title,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.collection);
      })
  }
  ngOnInit() {
    this.getCollection()
    this.itemsSubject = new BehaviorSubject<Array<any>>([]);
    this.items$ = this.itemsSubject.asObservable();

  }
  getCollection() {
    this.submiting = true;
    this.spinner.show();
    this.productService.getCollection(this.page.toString(), this.limit.toString()).subscribe(
      (result) => {
        this.submiting = false;
        this.itemsSubject.next(result.items);
        console.log("this.items$", this.items$);
        console.log("result.product", result.items);
        this.total_pages = result.pagination.total_pages;
        this.page = result.pagination.page;
        this.spinner.hide();
      },
      (error) => {
        this.submiting = false;
      }
    );

  }

  changePage(page) {
    this.page = page;
    this.getCollection();
  }
  // sorting type none || most-view.
  public onChangeSorting(val) {
    this.getCollection();
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
  }



  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }


}
