import { Component, OnInit, Input, ViewChild, SimpleChanges } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective, SwiperPaginationInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'app-main-carousel',
  templateUrl: './main-carousel.component.html',
  styleUrls: ['./main-carousel.component.scss']
})
export class MainCarouselComponent implements OnInit {

  @Input('slides') slides: Array<any> = [];

  public config: SwiperConfigInterface = {};

  private pagination: SwiperPaginationInterface = {
    el: '.swiper-pagination',
    clickable: true
  };
  index = 0
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  constructor() { }

  ngOnInit() { }

  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 1,
      spaceBetween: 0,
      // initialSlide: 1,
      keyboard: true,
      scrollbar: false,
      navigation: false,
      pagination: true,
      grabCursor: true,
      loop: false,
      preloadImages: true,
      lazy: true,
      
      autoplay: {
        delay: 5000,
        disableOnInteraction: true,
        // reverseDirection: true
      },
      speed: 1000,
      effect: "slide",
      
    }
  }
ngOnChanges(changes: SimpleChanges) {
  this.directiveRef?.stopAutoplay(true);
  this.directiveRef?.startAutoplay(true)
}
}
