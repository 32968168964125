import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { config } from './config';
import { Product, Tags, ProductDetails, Collection, ProductCollection, FilterAdvance } from '../modals/response';
import { CustomHeaderService } from './custom-header.service';
import { AuthServices } from './auth.service';
import {decryptAPI } from './security.provider';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, 
     
    private headerService: CustomHeaderService, 
    private authService: AuthServices) { }
  getProduct(filter = "none", subcategory_id = "none", category_id = "none", collection_id = "none", price: Array<any> = [], page = "none", limit = "none", search = undefined, dir = "none",rail_id = "none",): Observable<Product> {
    let params = {}
    if (filter != "none" && filter) {
      params["filter"] = filter;
    }
    if (rail_id != "none" && rail_id) {
      params["rail_id"] = rail_id;
    }
    if (subcategory_id != "none" && subcategory_id) {
      params["subcategory_id"] = subcategory_id;
    }
    if (category_id != "none" && category_id) {
      params["category_id"] = category_id;
    }
    if (collection_id != "none" && collection_id) {
      params["collection_id"] = collection_id;
    }
    if (price.length > 0 && price) {
      params["price"] = price;
    }
    if (page != "none" && page) {
      params["page"] = page;
    }
    if (limit != "none" && limit) {
      params["limit"] = limit;
    }
    if (search) {
      params["search"] = search;
    }
    if (dir != "none" && dir) {
      params["dir"] = dir;
    }
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    return this.http.post<string>(config.ApiUrl + "product", params, { headers }).pipe(
      shareReplay(1),
      map((data) => {
        return new Product(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getProductDetails(id: any): Observable<ProductDetails> {
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    return this.http.get<string>(config.ApiUrl + "product/" + id, { headers }).pipe(
      map((data) => {
        return new ProductDetails(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getTags(): Observable<Tags> {
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    return this.http.get<string>(config.ApiUrl + "tag", { headers }).pipe(
      map((data) => {
        return new Tags(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }

  getCollection(page = "none", limit = "none"): Observable<Collection> {
    let params = new HttpParams();
    if (page != "none" && page) {
      params = params.append("page", page.toString())
    }
    if (limit != "none" && limit) {
      params = params.append("limit", limit.toString())
    }
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    return this.http.get<string>(config.ApiUrl + "collection", { params, headers }).pipe(
      map((data) => {
        return new Collection(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getProductCollection(id: number, page = "none", limit = "none"): Observable<ProductCollection> {
    let params = new HttpParams();
    if (page != "none" && page) {
      params = params.append("page", page.toString())
    }
    if (limit != "none" && limit) {
      params = params.append("limit", limit.toString())
    }
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    return this.http.get<string>(config.ApiUrl + "collection/" + id, { params, headers }).pipe(
      map((data) => {
        return new ProductCollection(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getFilterAdvance(): Observable<FilterAdvance> {
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    return this.http.get<string>(config.ApiUrl + "filter/advance", { headers }).pipe(
      map((data) => {
        return new FilterAdvance(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
}
