import { Inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { Observable, BehaviorSubject, Subscription, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { config } from './config';
import { AuthService } from "angularx-social-login";
import { first, map, catchError } from 'rxjs/operators'
import { UserInfo } from '../modals/response';
import { CustomHeaderService } from './custom-header.service';
 import { decryptAPI } from './security.provider';

@Injectable({
  providedIn: 'root'
})
export class AuthServices {
  public isAuth = new BehaviorSubject<any>(null);
  private localStorageKey: string = "Authentication_Info";
  // user$: Observable<SocialUser>;
  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private headerService: CustomHeaderService,
     

    private socialauthService: AuthService) {
    // this.user$ = this.socialauthService.authState;
  }


  setAuthenticatedUser(userData, userInfo = null) {
    if (userInfo == null) {
      this.localStorageService.setItem(this.localStorageKey, userData);
      this.isAuth.next(userData);
    }
    else {
      const newUserData = userData;
      const newuserInfo = userInfo;
      newuserInfo["main_currency"] = userData.user_info.main_currency
      newUserData["user_info"] = newuserInfo;
      this.localStorageService.setItem(this.localStorageKey, newUserData);
      this.isAuth.next(newUserData);
    }
  }
  logout(device_guid: any): Observable<any> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.post(config.ApiUrl + "auth/logout", { device_guid: device_guid }, { headers });
  }
  logOutSocial() {
    this.socialauthService.authState.pipe(first()).subscribe((user) => {
      if (user) {
        this.socialauthService.signOut()
      }
    });
  }
  getAuthToken() {
    return this.Auth.token_info.access_token || null;
  }

  get currentUser() {
    console.log("isAuth", this.isAuth);
    if (!this.isAuth.value) {
      let auth: any = this.localStorageService.getItem(this.localStorageKey);
      this.isAuth.next(auth);
    }
    return this.isAuth.value;
  }

  getUserSocial(): any {
    return this.socialauthService.authState;
  }
  public resetAuthenticated(): void {
    this.localStorageService.setItem(this.localStorageKey, {});
    this.isAuth.next({});
  }

  public get Auth(): any {
    let auth: any = this.localStorageService.getItem(this.localStorageKey);
    if (Object.keys(auth).length === 0 && auth.constructor === Object)
      return {};
    else
      return auth;
  }
  get isLogined(): boolean {
    if (Object.keys(this.Auth).length === 0 && this.Auth.constructor === Object) {
      return false
    }
    else {
      if (this.Auth.user_info && this.Auth.token_info.access_token) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  updateUserInfo(formData): Observable<UserInfo> {
console.log("this.headerService",this.headerService);

    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.post<string>(config.ApiUrl + "user", formData, { headers }).pipe(
      map((data) => {
        return new UserInfo(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getUserInfo(): Observable<UserInfo> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.get<string>(config.ApiUrl + "getinfo", { headers }).pipe(
      map((data) => {
        return new UserInfo(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
}
