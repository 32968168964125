import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthServices } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class SignGuardService implements CanActivate {

  constructor(private authService: AuthServices, private router: Router) { }
  canActivate() {
    let auth = this.authService.Auth;
    if (Object.keys(auth).length === 0 && auth.constructor === Object) {
      return true
    }
    else {
      if (auth.user_info && auth.token_info.access_token) {
        this.router.navigate(["/"]);
        return false;
      }
      else {
        return true;
      }
    }
  }
}
