import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.sass']
})
export class CategoriesComponent implements OnInit {
  @Input() categories: Array<any>;
  @Input() multiLevel: boolean = false;
  @Input() categoryId: number;
  @Input() subcategoryId: number;
  @Input("scrollShow") scrollShow: boolean = false;
  @Output() getCategoryId: EventEmitter<Number> = new EventEmitter<Number>();
  @Output() getSubcategoryId: EventEmitter<Number> = new EventEmitter<Number>();
  constructor() { }

  ngOnInit() {
  }
  setCategory(id) {
    this.getCategoryId.emit(id)
  }
  setChildren(id) {
    this.getSubcategoryId.emit(id)
  }
}
