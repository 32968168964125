import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHeaderService } from './custom-header.service';
import { Observable, throwError } from 'rxjs';
import { config } from './config';
import { map, catchError } from 'rxjs/operators';
import { MyWishlist, WishlistToggle } from '../modals/response';
 import { decryptAPI } from './security.provider';

@Injectable({
  providedIn: 'root'
})
export class WishlistService {

  constructor(private http: HttpClient,
     
    private headerService: CustomHeaderService) { }
  ngOnInit() {
  }
  getMyWishlist(page = "none", limit = "none"): Observable<MyWishlist> {
    let params = new HttpParams();
    if (page != "none" && page) {
      params = params.append("page", page.toString())
    }
    if (limit != "none" && limit) {
      params = params.append("limit", limit.toString())
    }
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.get<string>(config.ApiUrl + "favorite", { params, headers }).pipe(
      map((data) => {
        return new MyWishlist(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  toggleMyWishlist(product_id: number): Observable<WishlistToggle> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.post<string>(config.ApiUrl + "favorite/toggle", { product_id: product_id }, { headers }).pipe(
      map((data) => {
        return new WishlistToggle(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    );
  }
}
