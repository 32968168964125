import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthServices } from './auth.service';

@Injectable()
export class RequestInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private authService: AuthServices) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // ///console.log(req.url);

    return next.handle(req).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this.handle400Error(req, error);
            case 401:
              return this.handle401Error(req, error);
          }
        }
        return this.generalErrorHandling(error);
      })
    );
  }

  handle400Error(req, error) {
    return this.generalErrorHandling(error);
  }

  handle401Error(req, error) {
    ///console.log("Enter Status 401 : ", req.url);
    /// log out ...
    this.authService.resetAuthenticated()
    this.router.navigate(["/login-register"]);
    return this.generalErrorHandling(error);
  }

  generalErrorHandling(error) {
    let errMsg = "";
    // Client Side Error
    if (error instanceof ErrorEvent) {
      errMsg = error.message;
    } else {
      if (error.status === 0) {
        errMsg = "Check your connection"
      }
      else if (error.status === 400) {
        errMsg = error.error;
      }
      else if (error.status === 402) {
        errMsg = error.error;
      }
      else {
        errMsg = error.error;
      }
    }
    // return an observable
    console.log("errMsgerrMsgerrMsg1", error);
    console.log("errMsgerrMsgerrMsg2", errMsg);

    return throwError(errMsg);
  }
}
