import { Injectable } from '@angular/core';
import { AuthServices } from './auth.service';
import { Router, Route, UrlSegment, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthServices, private router: Router) { }
  canActivate() {
    let auth = this.authService.Auth;
    if (Object.keys(auth).length === 0 && auth.constructor === Object) {
      this.router.navigate(["/login-register"]);
      return false
    }
    else {
      if (auth.user_info && auth.token_info.access_token) {
        return true;
      }
      else {
        this.router.navigate(["/login-register"]);
        return false;
      }
    }
  }
}
