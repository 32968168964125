import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PriceComponent } from './products/price/price.component';
import { ProductDetailsComponent } from './products/product-details/product-details.component';
import { ProductLeftSidebarComponent } from './products/product-left-sidebar/product-left-sidebar.component';
import { CommonModule } from '@angular/common';
import { ShopRoutingModule } from './shop-routing.module';
import { SharedModule } from '../shared/shared.module';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPaginationModule } from 'ngx-pagination';
// Import the library
import { NgxImageZoomModule } from 'ngx-image-zoom';

import { ProductCarouselTwoComponent } from './home-two/product-carousel-two/product-carousel-two.component';
import { ProductZoomComponent } from './products/product-details/product-zoom/product-zoom.component';

import { VariantsSelectComponent } from '../variants-select/variants-select.component';
import { CustomFormsModule } from 'ngx-custom-validators';
import { ProductsViewBySearchComponent } from './products/products-view-by-search/products-view-by-search.component';
import { ProductsViewByCategoryComponent } from './products/products-view-by-category/products-view-by-category.component';
import { CollectionComponent } from './products/collection/collection.component';
import { CollectionViewComponent } from './products/collection-view/collection-view.component';
import { ProductsViewByCollectionComponent } from './products/products-view-by-collection/products-view-by-collection.component';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';


@NgModule({
  declarations: [
    PriceComponent,
    ProductDetailsComponent,
    ProductLeftSidebarComponent,
    ProductsViewByCategoryComponent,
    ProductCarouselTwoComponent,
    ProductZoomComponent,
    VariantsSelectComponent,
    ProductsViewBySearchComponent,
    CollectionComponent,
    CollectionViewComponent,
    ProductsViewByCollectionComponent,
    
  ],
  imports: [
    CommonModule,
    ShopRoutingModule,
    SharedModule,
    SwiperModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxPaginationModule,
    NgxImageZoomModule,
    // NgxImageZoomModule.forRoot() // <-- Add this line
  ],
  exports: [
    ProductZoomComponent,
    CollectionComponent
  ],

  entryComponents: [
    ProductZoomComponent
  ],
})

export class ShopModule { }
