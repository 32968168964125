import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthServices } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ComplateGuardService implements CanActivate {

  constructor(private authService: AuthServices, private router: Router) { }
  canActivate() {
    let auth = this.authService.Auth;
    if (auth.user_info.first_login === 1) {
      this.router.navigate(["/complate-register"]);
      return false;
    }
    else {
      return true;
    }
  }
}
