import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategorayService } from 'src/app/services/categoray.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-products-view-by-search',
  templateUrl: './products-view-by-search.component.html',
  styleUrls: ['./products-view-by-search.component.scss']
})
export class ProductsViewBySearchComponent implements OnInit {

  /**For Products Viewer Style**/
public my_row:string;
public myCol_md_60:string;
public myCol_md_40:string;
public myImg:string;
public noMovement: boolean=false;

/**For Products Viewer Style End**/
  public sidenavOpen: boolean = true;
  public animation: any;   // Animation
  public sortByOrder: string = '';   // sorting
  // public page:any;
  // public tagsFilters: any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 20;

  category: any[] = [];
  tags: any[] = [];

  private itemsSubject;
  items$: Observable<Array<any>>;
  search: string = "";
  filter = "price";
  dir = "asc";
  // filter = "none";
  // subcategory_id;
  // category_id;
  // tag_ids: any[] = [];
  // price: any[] = [];
  page = 1;
  total_pages;
  limit = 20;
  submiting: boolean = false;
  currency: string = "";
  userInfo$: Observable<any>;
  constructor(private productService: ProductService,
    private categoryService: CategorayService,
    private route: ActivatedRoute,
    private authService: AuthServices,
    private titleService: Title,
    private translate: TranslateService,
    private spinner: NgxSpinnerService) {
      this.userInfo$ = this.authService.isAuth;
      this.route.params.subscribe(
        (params: Params) => {
          const search = params['search'];
          console.log("search", search);
          this.search = search;
          this.translate.get('titles').subscribe(value=> {
            this.titleService.setTitle(value.research_results + " : "+search);
          })
          this.onChangeSorting("most_view,none")
        }
      )
  }
  ngOnInit() {
    this.onChangeSorting("most_view,none")
    this.itemsSubject = new BehaviorSubject<Array<any>>([]);
    this.items$ = this.itemsSubject.asObservable();
    console.log("search", this.search);
    this.itemsSubject.next([]);

  }
  getProduct() {
    this.submiting = true;
    this.spinner.show();
    this.productService.getProduct(this.filter, "none", "none", undefined, undefined, this.page.toString(), this.limit.toString(), this.search, this.dir).subscribe(
      (result) => {
        this.submiting = false;
        this.itemsSubject.next(result.product.items);
        console.log("this.items$", this.items$);
        console.log("result.product", result.product);
        this.total_pages = result.pagination.total_pages;
        this.page = result.pagination.page;
        this.spinner.hide();
          // if (this.authService.isLogined) {
          //   this.userInfo$.subscribe(user =>
          //     this.currency = user.user_info?.main_currency
          //   )
          // }
          // else {
            this.currency = result?.meta?.main_currency;
          // }
      },
      (error) => {
        this.submiting = false;
      }
    );

  }

  changePage(page) {
    this.page = page;
    this.getProduct();
  }
  // sorting
  public onChangeSorting(val) {
    const value = val.split(',')
    console.log("typeof val", typeof value);
    console.log("val", value);
    this.filter = value[0];
    this.dir = value[1]
    this.getProduct();
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }


  changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
    if(viewCol=='100')
    {
      this.my_row="myRow";
      this.myCol_md_60="myCol-md-60";
      this.myCol_md_40="myCol-md-40";
      this.myImg="myImg";
      this.noMovement=true;

    }
    else{
      this.my_row=" ";
      this.myCol_md_60=" ";
      this.myCol_md_40=" ";
      this.myImg=" ";
      this.noMovement=false;

    }
  }
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }


}
