import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';


@Component({
  selector: 'app-header-two',
  templateUrl: './header-two.component.html',
  styleUrls: ['./header-two.component.sass']
})
export class HeaderTwoComponent implements OnInit {

  public currency: any;

  searchInput:string = ""
  indexProduct: number;
  public sidenavMenuItems: Array<any>;

  constructor(private router: Router,private route: ActivatedRoute,) {


  }

  ngOnInit() {
  /* this subscription will fire always when the url changes */
    this.router.events.subscribe(val=> {
      if (val instanceof NavigationEnd) {
        let curUrlTree = this.router.parseUrl(this.router.url);
        this.searchInput = curUrlTree?.root.children?.primary?.segments[1]?.path || "";
      }
    });


  }
  search(value: string) {
    if (value != "")
      this.router.navigate(['/products-by-search', value])
  }
}
