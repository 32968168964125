export class OMeta {
  message: string;
  status: boolean;
  statusNumber: number;
  storageUrl: string;
  totalProcessingTime: string;
  main_currency: string;
  constructor(response) {
    this.message = response?.meta?.message;
    this.status = response?.meta?.status;
    this.statusNumber = response?.meta?.statusNumber;
    this.storageUrl = response?.meta?.storageUrl;
    this.totalProcessingTime = response?.meta?.totalProcessingTime;
    this.main_currency = response?.meta?.main_currency
  }
  get meta(): object {
    return {
      message: this.message,
      status: this.status,
      statusNumber: this.statusNumber,
      storageUrl: this.storageUrl,
      totalProcessingTime: this.totalProcessingTime,
      main_currency: this.main_currency
    }
  }
}
export class OTokenInfo {
  access_token: boolean;
  token_type: string;
  expires_in: number;
  constructor(response) {
    this.access_token = response?.data?.access_token;
    this.token_type = response?.data?.token_type;
    this.expires_in = response?.data?.expires_in;
  }
  get token_info(): object {
    return {
      access_token: this.access_token,
      token_type: this.token_type,
      expires_in: this.expires_in
    }
  }
}
export class OUserInfo {
  id: number;
  address: string;
  city: any;
  postal_code: string;
  phone: string;
  name: string;
  email: string;
  image: string;
  provider_name: string;
  main_currency: string;
  account_type: string;
  cart_items: number;
  first_login: number;
  unread_notification: number;
  constructor(response) {
    this.id = response?.id;
    this.address = response?.address;
    this.city = response?.city;
    this.postal_code = response?.postal_code;
    this.phone = response?.phone;
    this.name = response?.name;
    this.email = response?.email;
    this.image = response?.image;
    this.provider_name = response?.provider_name;
    this.main_currency = response?.main_currency;
    this.account_type = response?.account_type;
    this.cart_items = response?.cart_items;
    this.first_login = response?.first_login;
    this.unread_notification = response?.unread_notification;
  }
  get userInfo(): object {
    return {
      id: this.id,
      address: this.address,
      city: this.city,
      postal_code: this.postal_code,
      phone: this.phone,
      name: this.name,
      email: this.email,
      image: this.image,
      provider_name: this.provider_name,
      main_currency: this.main_currency,
      account_type: this.account_type
    }
  }
}


export class OPagination {
  page: number;
  total: number;
  total_pages: number;
  per_page: number;


  constructor(pagination) {
    this.page = pagination?.page;
    this.total = pagination?.total;
    this.total_pages = pagination?.total_pages;
    this.per_page = pagination?.per_page;

  }
}

export class OSection {
  private id: number;
  private title: string;
  private description: string;
  private image: string;
  private category: string;
  private count: number;
  private discount: any;
  private price: number;
  private views: number;
  private has_discount: boolean;
  private is_wished: boolean;
  private free_delivery_type:string;
  private free_delivery_city_name:string;
  private after_discount_flag:boolean;
  private after_discount_val:number;
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.description = response?.description;
    this.image = response?.main_media;
    this.category = response?.category;
    this.count = response?.count;
    this.discount = response?.discount;
    this.price = response?.selling_price;
    this.views = response?.views;
    this.has_discount = response?.has_discount;
    this.is_wished = response?.is_wished;
    this.free_delivery_type = response?.free_delivery_type
    this.free_delivery_city_name = response?.free_delivery_city_name
    this.after_discount_flag = response?.after_discount_flag;
    this.after_discount_val = response?.after_discount_val;
  }
  get section(): object {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
      category: this.category,
      count: this.count,
      discount: this.discount,
      price: this.price,
      views: this.views,
      has_discount: this.has_discount,
      is_wished: this.is_wished,
      free_delivery_type:this.free_delivery_type,
      free_delivery_city_name:this.free_delivery_city_name,
      after_discount_flag:this.after_discount_flag,
      after_discount_val:this.after_discount_val,
    }
  }
}
export class OCollectionOrDiscounts {
  private id: number;
  private title: string;
  private description: string;
  private image: string;
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.description = response?.description;
    this.image = response?.image;
  }
  get collectionOrDiscounts(): object {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
    }
  }
}
export class OCategories {
  private id: number;
  private parent_id: number;
  private title: string;
  private main_image: string;
  private sort: number;
  constructor(response) {
    this.id = response?.id;
    this.parent_id = response?.parent_id;
    this.title = response?.title;
    this.main_image = response?.main_image;
    this.sort = response?.sort;
  }
  get categories(): object {
    return {
      id: this.id,
      parent_id: this.parent_id,
      title: this.title,
      image: this.main_image,
      sort: this.sort
    }
  }
}
export class OTags {
  private id: number;
  private title: string;
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
  }
  get tags(): object {
    return {
      id: this.id,
      title: this.title,
    }
  }
}
export class OProduct {
  id: number;
  title: string;
  description: string;
  brand_name: string;
  category_name: string;
  selling_price: number;
  has_discount: boolean;
  continue_selling: number;
  available_quantity: number;
  is_wished: boolean;
  free_delivery_type:string;
  free_delivery_city_name:string;
  after_discount_flag:boolean;
  after_discount_val:number;
  is_out_of_stock:boolean;
  discount:any;
  is_active:boolean;
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.description = response?.description;
    this.brand_name = response?.brand_name;
    this.category_name = response?.category_name;
    this.selling_price = response?.selling_price;
    this.has_discount = response?.has_discount;
    this.continue_selling = response?.continue_selling;
    this.available_quantity = response?.available_quantity;
    this.is_wished = response?.is_wished;
    this.free_delivery_type = response?.free_delivery_type
    this.free_delivery_city_name = response?.free_delivery_city_name
    this.after_discount_flag = response?.after_discount_flag;
    this.after_discount_val = response?.after_discount_val;
    this.is_out_of_stock = response?.is_out_of_stock;
    this.discount = response?.discount;
    this.is_active = response?.is_active;
  }
  get product(): object {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      brand_name: this.brand_name,
      category_name: this.category_name,
      selling_price: this.selling_price,
      has_discount: this.has_discount,
      continue_selling: this.continue_selling,
      available_quantity: this.available_quantity,
      is_wished: this.is_wished,
      free_delivery_type:this.free_delivery_type,
      free_delivery_city_name:this.free_delivery_city_name,
      after_discount_flag:this.after_discount_flag,
      after_discount_val:this.after_discount_val,
      is_out_of_stock:this.is_out_of_stock,
      discount:this.discount,
      is_active:this.is_active
    }
  }
}
export class OVariantsOption {
  title: string;
  value: string;
  hint_value: string;
  constructor(response) {
    this.title = response?.title;
    this.value = response?.value;
    this.hint_value = response?.hint_value;
  }
  get variants(): object {
    return {
      title: this.title,
      value: this.value,
      hint_value: this.hint_value,
    }
  }
}
export class ProductTargetOption {
  id: number;
  title: string;
  description: string;
  image: string;
  price: number;
  free_delivery_type:string;
  free_delivery_city_name:string;
  after_discount_flag:boolean;
  after_discount_val:number;
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.description = response?.description;
    this.image = response?.main_media
    this.price = response?.selling_price,
    this.free_delivery_type = response?.free_delivery_type
    this.free_delivery_city_name = response?.free_delivery_city_name
    this.after_discount_flag = response?.after_discount_flag;
    this.after_discount_val = response?.after_discount_val;
  }
  get product(): object {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
      price: this.price,
      free_delivery_type:this.free_delivery_type,
      free_delivery_city_name:this.free_delivery_city_name,
      after_discount_flag:this.after_discount_flag,
      after_discount_val:this.after_discount_val,
    }
  }
}
export class OTargetOption {
  id: number;
  quantity: number;
  sku: string;
  price_increment: number;
  product_id: number;
  price: number;
  image: any;
  product: any;
  constructor(response) {
    this.id = response?.id;
    this.quantity = response?.quantity;
    this.sku = response?.sku;
    this.price_increment = response?.price_increment;
    this.product_id = response?.product_id;
    this.price = response?.price;
    this.image = response?.image;
    this.product = new ProductTargetOption(response?.product).product;
  }
  get target_option(): any {
    if (this.id || this.quantity || this.sku || this.price_increment || this.product_id || this.price || this.product)
      return {
        id: this.id,
        quantity: this.quantity,
        sku: this.sku,
        price_increment: this.price_increment,
        product_id: this.product_id,
        price: this.price,
        image: this.image,
        product: this.product,

      }
    else null
    return null
  }
}
export class ODiscountsOption {
  id: number;
  title: string;
  type: string;
  min_cost: number;
  min_quantity: number;
  every_customer: number;
  one_to_each_customer: number;
  used_num: number;
  get_quantity: any;
  get_percentage: any;
  extra_money: number;
  description: string;
  product_option_id: any;
  image: any;
  target_option: any
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.type = response?.type;
    this.min_cost = response?.min_cost;
    this.min_quantity = response?.min_quantity;
    this.every_customer = response?.every_customer;
    this.one_to_each_customer = response?.one_to_each_customer;
    this.used_num = response?.used_num;
    this.get_quantity = response?.get_quantity;
    this.get_percentage = response?.get_percentage;
    this.extra_money = response?.extra_money
    this.description = response?.description;
    this.product_option_id = response?.product_option_id;
    this.image = response?.image;
    this.target_option = new OTargetOption(response?.target_option).target_option;
  }
  get discounts(): object {
    return {
      id: this.id,
      title: this.title,
      type: this.type,
      min_cost: this.min_cost,
      min_quantity: this.min_quantity,
      every_customer: this.every_customer,
      one_to_each_customer: this.one_to_each_customer,
      used_num: this.used_num,
      get_quantity: this.get_quantity,
      get_percentage: this.get_percentage,
      extra_money: this.extra_money,
      description: this.description,
      product_option_id: this.product_option_id,
      image: this.image,
      target_option: this.target_option
    }
  }
}
export class OOptions {
  id: number;
  after_discount_flag: boolean;
  after_discount_val: number;
  quantity_limit: number;
  price_increment: number;
  base_price: number;
  variants: OVariantsOption[];
  discounts: ODiscountsOption[];
  discount: any;
  is_out_of_stock: boolean;
  constructor(response) {
    this.id = response?.id;
    this.after_discount_flag = response?.after_discount_flag;
    this.after_discount_val = response?.after_discount_val;

    this.quantity_limit = response?.quantity_limit;
    this.price_increment = response?.price_increment;
    this.base_price = response?.base_price;
    this.variants = response?.variants?.map(element => new OVariantsOption(element).variants)
    this.discounts = response?.discounts?.map(element => new ODiscountsOption(element).discounts)
    this.discounts = response?.discount;
    this.is_out_of_stock = response?.is_out_of_stock;
  }
  get options(): object {
    return {
      id: this.id,
      quantity_limit: this.quantity_limit,
      price_increment: this.price_increment,
      base_price: this.base_price,
      variants: this.variants,
      discounts: this.discounts,
      after_discount_flag:this.after_discount_flag,
      after_discount_val:this.after_discount_val,
      discount:this.discount,
      is_out_of_stock:this.is_out_of_stock

    }
  }
}
export class OValuesVariants {
  id: number;
  value: string;
  variant_id: number;
  hint_value: string;
  constructor(response) {
    this.id = response?.id;
    this.value = response?.value;
    this.variant_id = response?.variant_id;
    this.hint_value = response?.hint_value;
  }
  get values(): object {
    return {
      id: this.id,
      value: this.value,
      variant_id: this.variant_id,
      hint_value: this.hint_value,
    }
  }
}
export class OVariants {
  id: number;
  title: string;
  category_id: number;
  type: number;
  values: OValuesVariants[];

  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.category_id = response?.category_id;
    this.type = response?.type;
    this.values = response?.values?.map(element => new OValuesVariants(element).values)
  }
  get variants(): object {
    return {
      id: this.id,
      title: this.title,
      category_id: this.category_id,
      type: this.type,
      values: this.values,
    }
  }
}

export class ODiscounts {
  item: any;
  options_ids: number[];
  constructor(response) {
    this.item = new ODiscountsOption(response?.item).discounts
    this.options_ids = response?.options_ids;
  }
  get discounts(): object {
    return {
      item: this.item,
      options_ids: this.options_ids,
    }
  }
}
export class OCartVariants {
  title: string;
  type: string;
  value: string;
  hint_value: string;
  constructor(response) {
    this.title = response?.title;
    this.type = response?.type;
    this.value = response?.value;
    this.hint_value = response?.hint_value;
  }
  get variants(): object {
    return {
      title: this.title,
      type: this.type,
      value: this.value,
      hint_value: this.hint_value,
    }
  }
}
export class ExtraOption {
  category: string;
  title: string;
  description: string;
  image: string;
  option_id: number;
  variants: OCartVariants[];
  is_found_extra_option:boolean;
  constructor(response) {
    this.category = response?.category;
    this.title = response?.title;
    this.description = response?.description;
    this.image = response?.image
    this.option_id = response?.option_id;
    this.variants = response?.variants?.map(element => new OCartVariants(element).variants)
    if(response == null) {
      this.is_found_extra_option = false;
    }
    else this.is_found_extra_option = true;
  }
  get extra_option(): object {
    return {
      category: this.category,
      title: this.title,
      description: this.description,
      image: this.image,
      option_id: this.option_id,
    }
  }
}
export class OCartOption {
  title: string;
  description: string;
  category: string;
  option_id: number;
  image: any;
  total_price: number;
  total_quantity: number;
  extra_quantity: number;
  quantity_limit: number;
  extra_option: ExtraOption;
  variants: OCartVariants[];
  constructor(response) {
    this.title = response?.title;
    this.description = response?.description;
    this.category = response?.category;
    this.option_id = response?.option_id;
    this.image = response?.image;
    this.total_price = response?.total_price;
    this.total_quantity = response?.total_quantity;
    this.extra_quantity = response?.extra_quantity;
    this.quantity_limit = response?.quantity_limit;
    this.extra_option = new ExtraOption(response?.extra_option);
    this.variants = response?.variants?.map(element => new OCartVariants(element).variants)
  }
  get options(): object {
    return {
      title: this.title,
      description: this.description,
      category: this.category,
      option_id: this.option_id,
      image: this.image,
      total_price: this.total_price,
      total_quantity: this.total_quantity,
      extra_quantity: this.extra_quantity,
      quantity_limit: this.quantity_limit,
      extra_option:this.extra_option,
      variants: this.variants
    }
  }
}
export class OCountry {
  id: number;
  code: string;
  name: string;
  flag_icon: any;
  state_key: string;
  constructor(response) {
    this.id = response?.id;
    this.code = response?.code;
    this.name = response?.name;
    this.flag_icon = response?.flag_icon;
    this.state_key = response?.state_key;
  }
  get country(): object {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      flag_icon: this.flag_icon,
      state_key: this.state_key,
    }
  }
}
export class OCity {
  id: number;
  country_id: number
  name: string;
  country: OCountry;
  shipping_cost: number;

  constructor(response) {
    this.id = response?.id;
    this.country_id = response?.country_id;
    this.name = response?.name;
    this.country = new OCountry(response?.country);
    this.shipping_cost = response?.shipping_cost;
  }
  get city(): object {
    return {
      id: this.id,
      country_id: this.country_id,
      name: this.name,
      country: this.country.country,
      shipping_cost: this.shipping_cost
    }
  }
}
export class OOrderItem {
  id: number;
  total_cost: number;
  status: string;
  code: string;
  can_cancelling: boolean;
  total_items: number;
  is_delivered: number;
  created_at: string;
  constructor(response) {
    this.id = response?.id;
    this.total_cost = response?.total_cost;
    this.status = response?.status;
    this.code = response?.code;
    this.can_cancelling = response?.can_cancelling;
    this.total_items = response?.total_items;
    this.is_delivered = response?.is_delivered;
    this.created_at = response?.created_at;
  }
  get item(): object {
    return {
      id: this.id,
      total_cost: this.total_cost,
      status: this.status,
      code: this.code,
      can_cancelling: this.can_cancelling,
      total_items: this.total_items,
      is_delivered: this.is_delivered,
      created_at: this.created_at
    }
  }
}
export class OOrderDetailsItem {
  cost: number;
  items: number;
  category: string;
  title: string;
  image: any;
  variants: OCartVariants[];
  constructor(response) {
    this.cost = response?.cost;
    this.items = response?.items;
    this.category = response?.category;
    this.title = response?.title;
    this.image = response?.image;
    this.variants = response?.variants?.map(element => new OCartVariants(element).variants)
  }
  get details(): object {
    return {
      cost: this.cost,
      items: this.items,
      category: this.category,
      title: this.title,
      image: this.image,
      variants: this.variants
    }
  }
}
export class OOrderDetails {
  id: number;
  total_cost: number;
  status: string;
  code: string;
  can_cancelling: boolean;
  total_items: number;
  details: OOrderDetailsItem[];
  constructor(response) {
    this.id = response?.id;
    this.total_cost = response?.total_cost;
    this.status = response?.status;
    this.code = response?.code;
    this.can_cancelling = response?.can_cancelling;
    this.total_items = response?.total_items;
    this.details = response?.details?.map(element => new OOrderDetailsItem(element).details)
  }
  get orderDetails(): object {
    return {
      id: this.id,
      total_cost: this.total_cost,
      status: this.status,
      code: this.code,
      can_cancelling: this.can_cancelling,
      total_items: this.total_items,
      details: this.details
    }
  }
}
export class OWishlistItem {
  id: number;
  title: string;
  description: string;
  image: string;
  price: number;
  discounts: boolean;
  category: string;
  has_discount: boolean;
  constructor(response) {
    this.id = response?.id;
    this.title = response?.title;
    this.description = response?.description;
    this.image = response?.main_media;
    this.price = response?.selling_price;
    this.discounts = response?.discounts;
    this.category = response?.category;
    this.has_discount = response?.has_discount;

  }
  get item(): object {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      image: this.image,
      price: this.price,
      discounts: this.discounts,
      category: this.category,
      has_discount: this.has_discount
    }
  }
}
