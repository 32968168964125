import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { CustomHeaderService } from './custom-header.service';
import { Observable, throwError } from 'rxjs';
import { config } from './config';
import { map, catchError } from 'rxjs/operators';
import { Checkout, Cities, CheckoutConfirm, PhoneVerified } from '../modals/response';
import { decryptAPI } from './security.provider';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(private http: HttpClient,
     
    private headerService: CustomHeaderService) { }
  checkout(promo_code: string = ""): Observable<Checkout> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    const body = {}
    if (promo_code) {
      body["promo_code"]= promo_code
    }
    return this.http.post<string>(config.ApiUrl + "checkout", body, { headers }).pipe(
      map((data) => {
        return new Checkout(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  checkoutConfirm(phone: string, city_id: number, target_name: string, target_address: string, postal_code: string = "",promo_code: string = "", pay_by_zain_cash:number = 0 , lat:number=-1 , lng:number=-1): Observable<CheckoutConfirm> {

    const body = { phone: phone, city_id: city_id, target_name: target_name, target_address: target_address }
    if (postal_code) {
      body["postal_code"] = postal_code;
    }
    if (promo_code) {
      body["promo_code"] = promo_code;
    }
    if(pay_by_zain_cash === 1) {
      body["pay_by_zain_cash"] = 1;
    }
    if(lat!=-1 && lng!=-1) {
      body["lat"] = lat;
      body["lng"] = lng;

    }
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.post<string>(config.ApiUrl + "checkout/confirm", body, { headers }).pipe(
      map((data) => {
        return new CheckoutConfirm(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  phoneNumberConfirm(phone: string, code: string): Observable<PhoneVerified> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    const body = { phone: phone, code: code }
    // const params = new HttpParams().set("phone", phone.toString()).set("code", code.toString());
    return this.http.post<string>(config.ApiUrl + "phone/verified", body, { headers }).pipe(
      map((data) => {
        return new PhoneVerified(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getAllCities(country_id?: number): Observable<Cities> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    // const params = new HttpParams().set("country_id", country_id.toString());
    return this.http.get<string>(config.ApiUrl + "city", { headers }).pipe(
      map((data) => {
        return new Cities(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
}
