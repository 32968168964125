import { Injectable } from '@angular/core';
import { ODiscountsOption } from '../modals/general';

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  maxQuantity: number = 1;
  quantity: number = 1;
  money: number = 0;
  priceAfterDiscount: number = 0;
  offPercn: number = 0;
  hint: string = null
  optionsSelected: any[] = []
  constructor() {
  }
  // to create alert message 
  alert(message, type) {
    let alertType = (type === "success") ? "success" : "error";
    let parentTemplate = `<div class="ualert-container" ></div>`
    let alertTemplate = `
        <div class="alert alert-result alert-${alertType} alert-dismissible fade show" role="alert">
          ${message}
        </div>
    `
    if (document.querySelector(".ualert-container") === null) {
      document.body.insertAdjacentHTML('afterbegin', parentTemplate);
    }
    document.querySelector(".ualert-container").insertAdjacentHTML('afterbegin', alertTemplate);
    let alert = document.querySelector(".alert-result");
    let timer = setTimeout(() => {
      alert.remove();
      clearInterval(timer);
    }, 3000);


  }

}
