import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServices } from 'src/app/services/auth.service';
import { WishlistService } from 'src/app/services/wishlist.service';
declare var ResizeObserver:any
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input("product") product: any;
  @Input("currency") currency: string = ""
  @Input("my_row") my_row: any;
  @Input("myCol_md_60") myCol_md_60: any;
  @Input("myCol_md_40") myCol_md_40: any;
  @Input("myImg") myImg: any;
  @Input("noMovement") noMovement: boolean;
  @ViewChild("productTop") productTop:ElementRef;
  addedToMyWishList: number = 0; // 0 : mean not click toggleMywishlist
  constructor(public translate: TranslateService,
    private spinner: NgxSpinnerService,
    private changeDetector: ChangeDetectorRef,
    private wishlistService: WishlistService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {

  }
ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
  const observer = new ResizeObserver(entries => {
    entries.forEach(entry => {
      // console.log("width", entry.contentRect.width);
      // console.log("height", entry.contentRect.height);
      // Then tell Angular to detect changes
      this.productTop.nativeElement.style.height = entry.contentRect.width + "px"
      this.changeDetector.detectChanges();
    });
  });
  
  observer.observe(this.productTop.nativeElement);
}
  toggleMyWishlist(id: number) {
    this.spinner.show();
    this.wishlistService.toggleMyWishlist(id).subscribe(
      (result) => {
        this.spinner.hide();
        console.log("result.getMyWishlist", result);
        this.snackBarOpen(result.meta.message);
        if (result.added === true)
          this.addedToMyWishList = 1; // 1 : mean clicked toggleMywishlist and added = true
        else if (result.added === false)
          this.addedToMyWishList = 2; // 2 : mean clicked toggleMywishlist and added = false
        console.log(" this.addedToMyWishList", this.addedToMyWishList);

      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message);
      }
    );
  }
  snackBarOpen(msg: string) {
    this._snackBar.open(msg, 'Cancel', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: "top",
    });
  }
}
