import { Component, OnInit, ViewChild } from '@angular/core';
import { ILogin, IRegister, ILoginSocial } from 'src/app/modals/request';
import { SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/app/services/base.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { RegisterService } from 'src/app/services/register.service';
import { ConfirmRegisterComponent } from '../confirm-register/confirm-register.component';
import { first } from 'rxjs/operators';
import { CartComponent } from '../pages/cart/cart.component';
import { CartService } from 'src/app/services/cart.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {
  loginForm: ILogin = {
    email: "",
    password: "",
    device_token: "",
    device_guid: "",
    device_type: "",
    finger_print_id: ""
  }
  registertionForm: IRegister = {
    name: "",
    email: "",
    password: "",
  }
  loginFormSocial = {
    email: "",
    provider_id: "",
    name: "",
    device_guid: "",
    device_type: "",
    device_token: "",
    finger_print: "",
    avatar:"",
  }
  translateContent = {
    fullName: "Name",
    email: "Email",
    password: "Password",
  }
  submitting: boolean = false;
  user: SocialUser;
  loggedIn: boolean;
  facebookSubsribe: Subscription;
  googleSubsribe: Subscription;
  onlangChangeContentSubscription: Subscription;

  constructor(private translate: TranslateService, 
    private titleService: Title,
    private loginServices: LoginService,
    private authService: AuthServices,
    private router: Router,
    private spinner: NgxSpinnerService,
    private baseService: BaseService,
    private cartService:CartService,
    private registerServices: RegisterService) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.login_register);
      })
  }

  ngOnInit() {
    this.cartService.setMyCartInArrayObservable(null)
    this.setContentLanguage()
    this.spinner.hide();
    this.onlangChangeContentSubscription = this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.setContentLanguage()
    });
  }
  login(form: HTMLInputElement) {
    this.loginForm.email = form.value["lemail"];
    this.loginForm.password = form.value["lpassword"];
    this.loginForm.device_guid = localStorage.getItem('static_generated_token');
    this.loginForm.device_type = "web";
    this.loginForm.finger_print_id = localStorage.getItem('finger_print_id');
    this.spinner.show();
    this.submitting = true;
    console.log("this.loginForm", this.loginForm);

    this.loginServices.signin(this.loginForm, "none").subscribe(
      (result) => {
        console.log("result is ", result);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(result.meta.message, "success");
        this.authService.setAuthenticatedUser({ token_info: result.tokenInfo, user_info: result.userInfo, meta: result.meta })
        if (result.userInfo.first_login === 0) {
          this.router.navigate(['/'])
        }
        else if (result.userInfo.first_login === 1) {
          this.router.navigate(['/complate-register'])
        }


      },
      (error) => {
        console.log("error is ", error);
        if(error.meta.statusNumber === 402) {
          this.router.navigateByUrl('/register-confirm', { state: { email: this.loginForm.email } });
        }
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  loginByFacebook() {
    this.authService.resetAuthenticated();
    this.authService.logOutSocial();
    this.loginServices.signInWithFacebook()
    this.facebookSubsribe = this.authService.getUserSocial().subscribe(
      (user) => {
        this.user = user;
        console.log("UserSocial", user);
        this.loggedIn = (user != null);
        if (this.loggedIn === true) {
          this.loginFormSocial.email = user.email;
          this.loginFormSocial.provider_id = user.id;
          this.loginFormSocial.device_type = "web";
          this.loginFormSocial.name = user.name;
          this.loginFormSocial.avatar = user.photoUrl;
          this.loginFormSocial.device_guid = localStorage.getItem('static_generated_token');
          this.loginFormSocial.finger_print = localStorage.getItem('finger_print_id');
          this.spinner.show();
          this.submitting = true;
          this.loginServices.signin(this.loginFormSocial, "facebook").pipe(first()).subscribe(
            (result) => {
              console.log("result is ", result);
              this.spinner.hide();
              this.submitting = false;
              this.baseService.alert(result.meta.message, "success");
              let userInfo = result.userInfo;
              userInfo.image = user.photoUrl;
              let meta = result.meta;
              this.authService.setAuthenticatedUser({ token_info: result.tokenInfo, user_info: userInfo, meta: meta })
              if (result.userInfo.first_login === 0) {
                this.router.navigate(['/'])
              }
              else if (result.userInfo.first_login === 1) {
                this.router.navigate(['/complate-register'])
              }
            },
            (error) => {
              console.log("error is ", error);
              this.spinner.hide();
              this.submitting = false;
              this.baseService.alert(error?.meta?.message, "error")

            }
          );
        }
      });
  }

  loginByGoogle() {
    this.authService.resetAuthenticated();
    this.authService.logOutSocial();
    this.loginServices.signInWithGoogle();
    this.googleSubsribe = this.authService.getUserSocial().subscribe(
      (user) => {
        this.user = user;
        console.log("UserSocial", user);
        this.loggedIn = (user != null);
        if (this.loggedIn === true) {
          this.loginFormSocial.email = user.email;
          this.loginFormSocial.provider_id = user.id;
          this.loginFormSocial.device_type = "web";
          this.loginFormSocial.name = user.name;
          this.loginFormSocial.avatar = user.photoUrl;
          this.loginFormSocial.device_guid = localStorage.getItem('static_generated_token');
          this.loginFormSocial.finger_print = localStorage.getItem('finger_print_id');
          this.spinner.show();
          this.submitting = true;
          this.loginServices.signin(this.loginFormSocial, "google").pipe(first()).subscribe(
            (result) => {
              console.log("result is ", result);
              this.spinner.hide();
              this.submitting = false;
              this.baseService.alert(result.meta.message, "success");
              let userInfo = result.userInfo;
              userInfo.image = user.photoUrl;
              let meta = result.meta;
              this.authService.setAuthenticatedUser({ token_info: result.tokenInfo, user_info: userInfo, meta: meta })
              if (result.userInfo.first_login === 0) {
                this.router.navigate(['/'])
              }
              else if (result.userInfo.first_login === 1) {
                this.router.navigate(['/complate-register'])
              }
            },
            (error) => {
              console.log("error is ", error);
              this.spinner.hide();
              this.submitting = false;
              this.baseService.alert(error?.meta?.message, "error")

            }
          );
        }
      });
  }
  register(form: HTMLInputElement) {
    console.log("form", form);
    this.registertionForm.name = form.value["rname"];
    this.registertionForm.email = form.value["remail"];
    this.registertionForm.password = form.value["rpassword"];
    this.spinner.show();
    this.submitting = true;
    this.registerServices.signup(this.registertionForm).subscribe(
      (result) => {
        console.log("result is ", result.message);
        console.log("result is ", result);
        this.spinner.hide();
        this.submitting = false;
        this.router.navigateByUrl('/register-confirm', { state: { email: this.registertionForm.email } });
        this.baseService.alert(result.message, "success")
      },
      (error) => {
        console.log("error is ", error);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  resetPassword(email) {
    this.spinner.show()
    this.loginServices.resetPasswordRequest(email).subscribe(
      (result) => {
        console.log("result is ", result);
        this.spinner.hide();
        this.baseService.alert(result.message, "success");
        this.router.navigateByUrl('/reset-password', { state: { email: email } });
      },
      (error) => {
        console.log("error is ", error);
        this.spinner.hide();
        this.submitting = false;
        this.baseService.alert(error?.meta?.message, "error")

      }
    );
  }
  canEnter(event, valid) {
    if (this.submitting || !valid) {
      event.preventDefault();
      return false;
    }
  }
  setContentLanguage() {
    if (this.translate.currentLang === "en") {
      this.translateContent.fullName = "Name";
      this.translateContent.email = "Email";
      this.translateContent.password = "Password";
    }
    else if (this.translate.currentLang === "ar") {
      this.translateContent.fullName = "الاسم";
      this.translateContent.email = "البريد الالكتروني";
      this.translateContent.password = "كلمة المرور";
    }
  }
  ngOnDestroy(): void {
    if (this.facebookSubsribe) {
      this.facebookSubsribe.unsubscribe()
    }
    if (this.googleSubsribe) {
      this.googleSubsribe.unsubscribe()
    }
    if (this.onlangChangeContentSubscription) {
      this.onlangChangeContentSubscription.unsubscribe()
    }
  }
}
