import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { AuthServices } from 'src/app/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-price',
  templateUrl: './price.component.html',
  styleUrls: ['./price.component.sass']
})
export class PriceComponent implements OnInit {

  public priceFrom: number = 0;
  public priceTo: number = 0;
  // Using Output EventEmitter
  @Output() priceFilters = new EventEmitter();

  // define min, max and range
  @Input("min") min: number = 1;
  @Input("max") max: number = 2000;
  public range = [100, 1000];
  @Input("currency") currency: string = "";
  public myThumFrom:boolean=true;
  public myThumbTo:boolean=true;

  constructor(private spinner: NgxSpinnerService) {
  }

  ngOnInit() {
   }
   ngOnChanges(changes: SimpleChanges) {
      this.priceFrom = this.min;
      this.priceTo = this.max;

  }
  

  // rangeChanged
  /*priceChanged(event: any) {
    setInterval(() => {
      this.priceFilters.emit(event);
    }, 1000);
    
  }*/

priceFilter() {
    this.priceFilters.emit({
      priceFrom: this.priceFrom,
      priceTo: this.priceTo
    });
  }

  showSpinner()
  {
    this.spinner.show();
    this.myThumFrom=false;
    this.myThumbTo=false;

  }
  
  showThumbs()
  {
    this.myThumFrom=true;
    this.myThumbTo=true;

  }
}
