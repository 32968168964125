import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouterModule,
  Routes,
  UrlSegment
} from '@angular/router';


export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  
  storedRouteHandles = new Map<string, DetachedRouteHandle>();

  /**
   * Determines if this route (and its subtree) should be detached to be reused later
   * @param route
   */
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    console.log('[router-reuse] checking if this route should be re used or not', route);
    if (!route.routeConfig || route.routeConfig.loadChildren) {
      return false;
    }
    /** Whether this route should be re used or not */
    let shouldReuse = false;
    
    if ( route.routeConfig.data ) {
      route.routeConfig.data.reuseComponent ? shouldReuse = true : shouldReuse = false;
    }
    
    return shouldReuse;
  }

  /**
   * Stores the detached route.
   */
  store( route: ActivatedRouteSnapshot, handler: DetachedRouteHandle ): void {
    console.log('[store] storing handler',handler);
    console.log("[store] this.storedRouteHandles",this.storedRouteHandles);
    
    if ( handler ) {
      // console.log("this.getUrl(route) From Store",this.getUrl(route));
      
      // this.handlers[this.getUrl(route)] = handler;
      this.storedRouteHandles.set(this.getUrl(route), handler);
    }
  }

  /**
   * Determines if this route (and its subtree) should be reattached
   * @param route Stores the detached route.
   */
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    // console.log("this.handlers form shooooooooooo",this.storedRouteHandles);
    
    // console.log('[router-reuse] checking if it should be re attached' , this.getUrl(route));
    //  !!this.handlers[this.getUrl(route)];
    return this.storedRouteHandles.has(this.getUrl(route))
  }

  /**
   * Retrieves the previously stored route
   */
//    retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
//     if (!route.routeConfig) return null;
//     if (route.routeConfig.loadChildren) return null;
//     return this.handlers[route.routeConfig.path];
// }
  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    console.log("retrieve => route",route);
    
    if (!route.routeConfig) return null;
    if (route.routeConfig.loadChildren || route.routeConfig.children ) return null;
    // console.log("this.handlers form retrieveretrieve retrieve",this.storedRouteHandles);
    // console.log("this.handlers form retrieveretrieve retrieve this.getUrl(route)",this.getUrl(route));
    // console.log("this.handlers form retrieveretrieve retrieve route",route);

    // return this.handlers[this.getUrl(route)];
    return this.storedRouteHandles.get(this.getUrl(route)) as DetachedRouteHandle;
  }

  /**
   * Determines if a route should be reused
   * @param future
   * @param current
   */
  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    /** We only want to reuse the route if the data of the route config contains a reuse true boolean */
    
    /**
     * Default reuse strategy by angular assers based on the following condition
     * @see https://github.com/angular/angular/blob/4.4.6/packages/router/src/route_reuse_strategy.ts#L67
     */
    const defaultReuse = (future.routeConfig === current.routeConfig);

    // If either of our reuseUrl and default Url are true, we want to reuse the route
    //

    return  defaultReuse;
  }

  /**
   * Returns a url for the current route
   * @param route
   */
  getUrl(route: ActivatedRouteSnapshot): string {
    // console.log('getPath',route);
    if (route.routeConfig !== null) {
      return route['_routerState'].url.replace(/[\/|?|=|&|-]/g, '_')
    }
    return '';
  }
  // private getUrl(route: ActivatedRouteSnapshot): string {
  //   let path = "";
  //   if (route.routeConfig != null && route.routeConfig.path != null)
  //   path = route.routeConfig.path;
  //   console.log("route['_routerState'].url.replace(/\//g, '_')",path);
  //   return path;
  //   }
}
