import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CustomHeaderService } from './custom-header.service';
import { config } from './config';
import { map, catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { Categories } from '../modals/response';
import { decryptAPI } from './security.provider';

@Injectable({
  providedIn: 'root'
})
export class CategorayService {

  constructor(private http: HttpClient, 
     
    private headerService: CustomHeaderService) { }
  getCategories(parent_id): Observable<Categories> {
    const headers = new HttpHeaders(this.headerService.basicHeaders())
    let params = new HttpParams();
    if (parent_id) {
      params = params.append('parent_id', parent_id)
    }

    return this.http.get<string>(config.ApiUrl + "category", { headers, params }).pipe(
      map((data) => {
        return new Categories(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }

}
