import { Component, OnInit, ViewChild } from '@angular/core';
import { IConfirm } from 'src/app/modals/request';
import { SocialUser } from 'angularx-social-login';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseService } from 'src/app/services/base.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';
import { RegisterService } from 'src/app/services/register.service';
import { LoginRegisterComponent } from '../login-register/login-register.component';
import { CheckoutService } from 'src/app/services/checkout.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-complate-register',
  templateUrl: './complate-register.component.html',
  styleUrls: ['./complate-register.component.sass']
})
export class ComplateRegisterComponent implements OnInit {
  userInfo$;
  oldUser: any;
  form = {
    address: "",
    town: { shipping_cost: undefined, country: { state_key: undefined } },
    postcode: "",
    phone: ""
  }
  translateContent = {
    address: "Address Line",
    postalCode: "Postal Code",
    phone: "Phone",
    code: "Code"
  }
  mobNumberPattern = "";
  hintMobNumberPattern = ""
  cities: any[];
  submitting: boolean = false;
  onlangChangeContentSubscription: Subscription;
  userInfoSubscription: Subscription;
  phoneNumberCode:string = "";
  @ViewChild("LoginRegisterComponent") loginRegisterComponent: LoginRegisterComponent;
  constructor(private translate: TranslateService,
    private titleService: Title,
    private router: Router,
    private authService: AuthServices,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private checkoutService: CheckoutService,
    private registerServices: RegisterService) {
    this.translate.get('titles').subscribe(value=> {
      this.titleService.setTitle(value.complate_register);
    })
    this.userInfo$ = this.authService.isAuth;
    this.setContentLanguage()
    this.onlangChangeContentSubscription = this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.setContentLanguage()
    });
  }

  ngOnInit() {
    this.getUserInfo();
    this.getAllCities()
  }
  setContentLanguage() {
    if (this.translate.currentLang === "en") {
      this.translateContent.address = "Address Line";
      this.translateContent.postalCode = "Postal Code";
      this.translateContent.phone = "Phone Number";
      this.translateContent.code = "Code";
    }
    else if (this.translate.currentLang === "ar") {
      this.translateContent.address = "عنوان الطريق";
      this.translateContent.postalCode = "الكود البريدي";
      this.translateContent.phone = "رقم الهاتف";
      this.translateContent.code = "الكود";
    }
  }
  updateUserInfo() {
    let formData: FormData = new FormData();
    formData.append('phone', this.phoneNumberCode+this.form.phone);
    formData.append('city_id', this.form.town["id"]);
    formData.append('city_id', this.form.town["id"]);
    if (this.form.postcode) {
      formData.append('postal_code', this.form.postcode);
    }
    formData.append('address', this.form.address);

    this.spinner.show();
    this.authService.updateUserInfo(formData).subscribe(
      (result) => {
        console.log("result.updateUserInfo", result.user);
        this.authService.setAuthenticatedUser(this.oldUser, result.user)
        this.spinner.hide();
        this.snackBarOpen(result?.meta?.message);
        this.router.navigate(['/'])
      },
      (error) => {
        this.spinner.hide();
        this.snackBarOpen(error?.meta?.message);
      }
    );
  }
  getUserInfo() {
    this.userInfoSubscription = this.userInfo$.subscribe(user => {
      console.log("userProfile", user?.user_info)
      this.oldUser = user;
    });

  }
  getAllCities() {
    this.spinner.show();
    this.checkoutService.getAllCities().subscribe(
      (result) => {
        console.log("result.getAllCities", result);
        this.cities = result.data;
        this.form.town = this.cities[0];
        let partOne: string
        let key: string = this.form.town?.country?.state_key;
        this.phoneNumberCode = key;

        if (this.form.town?.country?.state_key) {
          partOne = "^((\\" + key + ")|00" + key.slice(1) + ")"
          this.hintMobNumberPattern = key + "********"
        }
        else {
          partOne = "^(0)";
          this.hintMobNumberPattern = "0********"
        }
        this.mobNumberPattern = "[0-9]*"
        console.log("this.mobNumberPattern ", this.mobNumberPattern);

        console.log("this.form.town", this.form.town);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }
  changeCity() {
    let partOne: string
    let key: string = this.form.town?.country?.state_key;
    this.phoneNumberCode = key;
    if (this.form.town?.country?.state_key) {
      partOne = "^((\\" + key + ")|00" + key.slice(1) + ")";
      this.hintMobNumberPattern = key + "********"
    }
    else {
      partOne = "^(0)"
      this.hintMobNumberPattern = "0********"
    }
    this.mobNumberPattern =  "[0-9]*"
    console.log("this.mobNumberPattern ", this.mobNumberPattern);
  }
  canEnter(event, valid) {
    if (this.submitting || !valid) {
      event.preventDefault();
      return false;
    }
  }
  snackBarOpen(msg: string) {
    this._snackBar.open(msg, 'Cancel', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: "top",
    });
  }
  ngOnDestroy(): void {
    if (this.onlangChangeContentSubscription) {
      this.onlangChangeContentSubscription.unsubscribe()
    }
    if (this.userInfoSubscription) {
      this.userInfoSubscription.unsubscribe();
    }
  }
}
