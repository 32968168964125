import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class CustomHeaderService {
  private localStorageKey: string = "Authentication_Info";

  private access_token: string;
  lang: string;
  constructor(private localStorageService: LocalStorageService, public translate: TranslateService) {
  }

  basicHeaders(withoutAuthorization: boolean = false): any {
    console.log("basicHeaders basicHeaders basicHeaders basicHeaders");
    
    let languageHeader = this.createLanguageHeader();
    // let webHeader = this.createWebHeader();
    let resultHeader;
    if (withoutAuthorization === true) {
      resultHeader = {
        // "Content-Type": "application/json",
        "Accept": "application/json",
        ...languageHeader,
      };
    }
    else if (withoutAuthorization === false) {
      let AuthorizationHeader = this.createAuthorizationHeader();
      resultHeader = {
        // "Content-Type": "multipart/form-data",
        "Accept": "application/json",
        ...AuthorizationHeader,
        ...languageHeader,
      };
    }
    return resultHeader;
  }

  private createAuthorizationHeader() {
    let authInfo = this.localStorageService.getItem(this.localStorageKey);
    if (authInfo?.length === 0) {
      this.access_token = null;
    } else {
      this.access_token = this.localStorageService.getItem(this.localStorageKey)?.token_info?.access_token;
    }
    // ///console.log(this.access_token);

    if (this.access_token != null) {
      return { Authorization: "Bearer " + this.access_token };
    } else {
      return {};
    }
  }

  private createLanguageHeader() {
    const lang = localStorage.getItem('locale');
    return { "language": lang };
  }
}
