import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';

import { MainComponent } from './components/main/main.component';


import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';
import { ColorOptionsComponent } from './components/color-options/color-options.component';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { RequestInterceptorService } from './services/request-interceptor.service';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { ConfirmRegisterComponent } from './components/confirm-register/confirm-register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { HomeTwoComponent } from './components/shop/home-two/home-two.component';
import { MainCarouselComponent } from './components/shop/main-carousel/main-carousel.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { CommonModule } from '@angular/common';
import { ComplateRegisterComponent } from './components/complate-register/complate-register.component';
import {
  StoreRouterConnectingModule,
  routerReducer,
  RouterStateSerializer
} from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { EffectsModule } from "@ngrx/effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import * as fromHome from './store/home/home.reducer';
import { HomeEffects } from './store/home/home.effects';
import { Reducers } from './store';
import { HomeService } from './services/home.service';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from './custom-route-reuse-strategy';
let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("438909475738-h32jkni07c3p569qviomoauc2sk6nef3.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("315648076136149")
  }
]);
export function provideConfig() {
  return config;
}
const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    ColorOptionsComponent,
    LoginRegisterComponent,
    ConfirmRegisterComponent,
    ResetPasswordComponent,
    HomeTwoComponent,
    MainCarouselComponent,
    MyProfileComponent,
    ComplateRegisterComponent,
    // HomeComponent
    // CategoryMenuComponent,



  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ShopModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NoopAnimationsModule,
    NgxImgZoomModule,
    HttpClientModule,
    SocialLoginModule,
    NgxSpinnerModule,
    SwiperModule,

    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    // StoreModule.forRoot({}),


    // EffectsModule.forRoot([]),
    // StoreModule.forFeature("Home", Reducers.Home),
    // EffectsModule.forFeature([HomeEffects]),
    // AppRoutingModule,
    // StoreRouterConnectingModule.forRoot({
    //   serializer: CustomSerializer,
    // }),
    // StoreDevtoolsModule.instrument(),

    

  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    Title,
    // {
    //   provide: RouteReuseStrategy,
    //   useClass: CustomRouteReuseStrategy 
    // }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
