import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LoginRegisterComponent } from './components/login-register/login-register.component';
import { ConfirmRegisterComponent } from './components/confirm-register/confirm-register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SignGuardService } from './services/sign-guard.service';
import { AuthGuardService } from './services/auth-guard.service';
import { HomeTwoComponent } from './components/shop/home-two/home-two.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { ComplateRegisterComponent } from './components/complate-register/complate-register.component';
import { ComplateGuardService } from './services/complate-guard.service';
import { ComplatePageGuardService } from './services/complate-page-guard.service';


const appRoutes: Routes = [
  {
    path: '',
    component: HomeTwoComponent,
    data: {
      reuseComponent: true
    }
  },
  {
    path: '',
    children: [
      {
        path: '',
        loadChildren: () => import('./components/shop/shop.module').then(m => m.ShopModule),
        data: {
          reuseComponent: true
        }
      },
      {
        path: '',
        loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule),
        data: {
          reuseComponent: true
        }
      },
    ],
    // data: {
    //   reuseComponent: true
    // }
  },
  { path: 'login-register', component: LoginRegisterComponent, canActivate: [SignGuardService] },
  { path: 'register-confirm', component: ConfirmRegisterComponent, canActivate: [SignGuardService] },
  { path: 'complate-register', component: ComplateRegisterComponent, canActivate: [AuthGuardService, ComplatePageGuardService] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [SignGuardService] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuardService, ComplateGuardService] },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(appRoutes,{preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
