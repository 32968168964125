import { ElementRef, HostBinding, HostListener, Input, SimpleChanges } from '@angular/core';
import { Renderer2 } from '@angular/core';
import { OnInit } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[setHeight]'
})
export class SetHeightDirective implements OnInit {
  @Input("size") size: number = 1;
  @HostBinding('style.height') height: string = "0px";
  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }
  ngOnInit(): void {
  }
ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
  this.height =  this.elementRef.nativeElement.offsetWidth*Number(this.size) + "px";
  
}
ngOnChanges(changes: SimpleChanges): void {
  //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
  //Add '${implements OnChanges}' to the class.
  this.height =  this.elementRef.nativeElement.offsetWidth*Number(this.size) + "px";
}
  @HostListener('window:resize', ['$event']) resizeElement(){
    this.height =  this.elementRef.nativeElement.offsetWidth*Number(this.size) + "px";
    // console.log(" @HostListener",this.elementRef);
    
  }

  setHeight(size: number) {
     
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'height',
      this.elementRef.nativeElement.width*size + "px"
    )
  }
}
