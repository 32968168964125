import { Component, OnInit, ViewChild, Output, EventEmitter, HostListener, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ProductService } from 'src/app/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { OProduct, OOptions, OVariants, ODiscounts, ODiscountsOption } from 'src/app/modals/general';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/services/cart.service';
import { BaseService } from 'src/app/services/base.service';
import { WishlistService } from 'src/app/services/wishlist.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

declare var ResizeObserver:any
@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  public config: SwiperConfigInterface = {};
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();

  @ViewChild('zoomViewer', { static: true }) zoomViewer;
  @ViewChild(SwiperDirective, { static: true }) directiveRef: SwiperDirective;
  public product: OProduct;
  images: any[];
  fullOptions: any[] = [];
  options: any[] = [];
  optionsFiltered: OOptions[];
  variants: OVariants[];
  discounts: ODiscounts[];
  discount_hint:string;
  color: any;
  size: any;
  variantsSelectsFilteredOptions: any[] = [];
  variantsSelected: Object = {};
  // proretySelected: number = 1000;
  variantValues: Object = {};
  // variantDisableValues: Object = {};
  public image: any;
  // public zoomImage: any;

  public quantity: number = 1;
  money: number = 0;
  priceAfterDiscount: number = 0;
  offPercn: number = 0;
  hint: string = null
  index: number;
  bigProductImageIndex = 0;
  submiting: boolean = false;
  maxQuantity: number = 100;
  addedToMyWishList: number = 0; // 0 : mean not click toggleMywishlist
  has_discount: boolean = false;
  similar_products: any;
  currency: string = "";
  userInfo$: Observable<any>;
  translationMessages:any;
  @ViewChild("productTop") productTop:ElementRef;
  productDetails:any;
  constructor(private route: ActivatedRoute,
    private productsService: ProductService,
    private translate: TranslateService,
    private titleService: Title,
    private authService: AuthServices,
    private spinner: NgxSpinnerService,
    private _snackBar: MatSnackBar,
    private cartService: CartService,
    private changeDetector: ChangeDetectorRef,
    private wishlistService: WishlistService,
    private baseServices: BaseService) {
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.products);
      })
      this.translate.get('messages').subscribe(value=> {
        this.translationMessages = value;
      })
      this.route.params.subscribe(params => {
        const id = params['id'];
        this.getProductDetails(id);
      });
      this.userInfo$ = this.authService.isAuth;
  }

  ngOnInit() {
    // this.getRelatedProducts();
  }
 
  ngAfterViewInit() {
    this.config = {
      observer: true,
      slidesPerView: 3,
      spaceBetween: 10,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      loop: false,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 3,
        },


      }
    }
    const observer = new ResizeObserver(entries => {
      entries.forEach(entry => {
        console.log("width", entry.contentRect.width);
        console.log("height", entry.contentRect.height);
        // Then tell Angular to detect changes
        this.productTop.nativeElement.style.height = entry.contentRect.width + "px"
        this.changeDetector.detectChanges();
      });
    });
    
    observer.observe(this.productTop.nativeElement);
  }
  getProductDetails(id) {
    this.submiting = true;
    this.spinner.show();
    this.productsService.getProductDetails(id).subscribe(
      (result) => {
          // if (this.authService.isLogined) {
          //   this.userInfo$.subscribe(user =>
          //     this.currency = user.user_info?.main_currency
          //   )
          // }
          // else {
            this.currency = result?.meta?.main_currency;
          // }
        this.submiting = false;
        this.spinner.hide();
        this.productDetails = result
        this.product = result.product;
        this.titleService.setTitle(this.titleService.getTitle() + " | "+result.product.title);
        this.images = result.images;
        this.has_discount = result.has_discount;
        this.similar_products = result.similar_products
        this.discount_hint = result.discount_hint;
        console.log("sortingArrayOptions", result.options);
        this.fullOptions = result.options

        this.variants = result.variants;
        if (this.variants.length > 0) {
          this.getAllVariantOptions(result.options);
        }
        this.getPriceOptionStatic();
        this.discounts = result.discounts;
        console.log("result.productDetails", result);
        console.log("this.discounts", this.discounts);


      },
      (error) => {
        this.submiting = false;
        this.spinner.hide();
      }
    );

  }



  public selectImage(index) {
    console.log(index)
    this.bigProductImageIndex = index;
  }

  public increment() {
    if (this.quantity >= this.maxQuantity) {
      this.snackBarOpen('You cannot add more, there is no more quantity');
    }
    else {
      this.quantity += 1;
      this.getPriceOptionStatic()
    }
  }

  public decrement() {
    if (this.quantity > this.maxQuantity) {
      this.snackBarOpen('You cannot add more, there is no more quantity');
    }
    else {
      if (this.quantity > 1) {
        this.quantity -= 1;
        this.getPriceOptionStatic()
      }
    }
  }

// Methode for get all variant option for all selects
  getAllVariantOptions(options:any[]) {
    options.forEach(option => {
      this.options.push(option.variants)
    })
    console.log("this.options",this.options)
    console.log("this.variants",this.variants)
    this.variantsSelectsFilteredOptions[this.variants[0].title] = this.variants[0].values // Fill all options for first select
    this.variants.forEach((variant , index) => { // Fill filter options by first option for other selects
      this.variantsSelected[variant.title] = variant.values[0]
      if(index < this.variants.length-1) {
        this.optionsFilter(index+1) // to filter all other options by depend on first option selected
      }
    })
    console.log("this.variantsSelected",this.variantsSelected)

  }
  // to filter the options using index 
  optionsFilter(index) {
    console.log("this.variants[index].values",this.variants[index].values);
    console.log("this.variantsSelectsFilteredOptions Before Filter",this.variantsSelectsFilteredOptions)
    const previousVariantsTitles = this.findPreviousVariantsTitles(this.variants[index].title);
    console.log("this.findPreviousVariantsTitles(title)", previousVariantsTitles)
    //to filter the option for the entered title based on the previous title
    this.variantsSelectsFilteredOptions[this.variants[index].title] = this.variants[index].values.filter(value => this.filterOptionByVariantSelected(value, this.variants[index].title, previousVariantsTitles))
    console.log("this.variantsSelectsFilteredOptions After Filter",this.variantsSelectsFilteredOptions)
  }

  filterOptionByVariantSelected(value, title:string , previousVariantsTitles:string[]): boolean {
    let result:boolean = false
    console.log("title",title)
    let optionsfilterd:any[] = this.options;
    previousVariantsTitles.forEach(prevTitle => {
      optionsfilterd = optionsfilterd.filter(option => {
        let filterResult:boolean = false;
        option.forEach(item => {
          if(item.title == prevTitle && item.value == this.variantsSelected[prevTitle].value) filterResult = true;     
        });
        return filterResult
      })
    })
    console.log("optionsfilterd",optionsfilterd)

    optionsfilterd.forEach(option => { 
      option.forEach(element => {
        if(element.title == title && element.value == value.value) {
          result = true
        }
      });
    })
    return result
  }
  /* 
   ** to find the titles of the previous options in relation to a specific title 
   ** we take advantage of this methode to filter the option for the entered title based on the previous title
  */
  findPreviousVariantsTitles(title:string):string[]{
    let titles:string[] = [];
    let stop = false;
    this.variants.forEach(variant => {
      if(variant.title === title) stop = true;
      else if(!stop) {
        titles.push(variant.title)
      }
    })
    return titles;
  }
  variantSelect(selectedValue) {
    console.log("variantSelect", selectedValue);
    this.variantsSelected[selectedValue.title] = selectedValue.value;
    this.variants.forEach((variant , index) => {
      if(index != 0) {
        if(index < this.variants.length-1) {
          this.optionsFilter(index+1)
        }
      }

    })
    console.log("this.variantsSelected",this.variantsSelected)
    this.getPriceOptionStatic();
  }
  // To get all options for variant selected
  get getOptionByVariantSelected(): any[] {
    const optionSelected: any[] = this.fullOptions?.filter(option => {
      let result:boolean = true;
      option.variants.forEach(variant => {
        if(variant.value !== this.variantsSelected[variant.title].value) result = false;
      });
      return result;
    })
    // console.log("getOptionByVariantSelected", optionSelected);
    return optionSelected;
  }
  getPriceOptionStatic() {
    console.log("=================== getPriceOption() ============");
    console.log("getPriceOption=>this.getOptionByVariantSelected", this.getOptionByVariantSelected);
    this.maxQuantity = this.getOptionByVariantSelected[0].quantity_limit;
    this.money = this.getOptionByVariantSelected[0].base_price;
    if(this.getOptionByVariantSelected[0].after_discount_flag) {
      this.priceAfterDiscount = this.getOptionByVariantSelected[0].after_discount_val
      this.offPercn = this.getOptionByVariantSelected[0].discounts.amount * 100
    }
    else this.priceAfterDiscount =0;
  }
  getPriceOption() {
    console.log("=================== getPriceOption() ============");
    console.log("getPriceOption=>this.getOptionByVariantSelected", this.getOptionByVariantSelected);
    this.maxQuantity = this.getOptionByVariantSelected[0].quantity_limit;

    if (this.quantity > this.maxQuantity && this.maxQuantity !==0) this.quantity = this.maxQuantity;
      this.money = this.getOptionByVariantSelected[0].base_price
    this.money = this.getOptionByVariantSelected[0].base_price * this.quantity;
    
    const priceOfOption: number = this.getOptionByVariantSelected[0].base_price;
    // this.priceAfterDiscount = priceOfOption
    let usedNum: number = 0;
    let toContinue: boolean = false;
    let nonCriticalQuant: number = this.quantity;
    let nonCriticalPercn: number = 0;
    let criticalQuant: number = 0;
    let criticalPercn: number = 0;
    let extraMoney: number = 0;
    let percentageDiscount:number  =0;
    let getPercentage:number  =0;

    let hint: string = null
    this.getOptionByVariantSelected[0]?.discounts.forEach((discount: ODiscountsOption) => {
      const type: string = discount.type;
      console.log("DD======discount====>",discount);
      
      if (type == 'buyXgetY') {
        console.log("type", type);

        let verb = discount.min_cost == 0 ? "buys" : 'spends ';
        console.log("verb", verb);
        let percentage = discount.get_percentage == null ? null : (discount.get_percentage * 100);
        console.log("percentage", percentage);
        let items = (verb == 'buys') ? (discount.min_quantity > 1?discount.min_quantity +" " +this.translationMessages.products:this.translationMessages.product) : discount.min_cost + " " + this.currency;
        console.log("items", items);
        let postFix = percentage == null ? this.translationMessages.for_free : this.translationMessages.with_discount + percentage + "%";
        console.log("postFix", postFix);
        toContinue = (verb == 'buys') ? (this.quantity >= discount.min_quantity ? true : false) : (this.money >= discount.min_cost ? true : false);
        console.log("toContinue", toContinue);
        if (toContinue) {
          usedNum = Math.floor(this.quantity / discount.min_quantity);
          console.log("usedNum", usedNum);
          usedNum = usedNum <= discount.used_num ? usedNum : discount.used_num;
          console.log("usedNum", usedNum);
          let amount = discount.one_to_each_customer == 1 ? discount.get_quantity : (usedNum * discount.get_quantity);
          console.log("amount", amount);
          // ---------------- Hint Message ---------------------
          hint = this.translationMessages.you +" "+ this.translationMessages[verb] + " " + items + " " +this.translationMessages.of + " "+ this.product?.title + " " + this.translationMessages.get + " " + (discount.get_quantity > 1?discount.get_quantity +this.translationMessages.products:this.translationMessages.product)+ " " + this.translationMessages.of + " " + `<a class="product-link-hint" href="/product/${discount.target_option.product_id}" ng-reflect-router-link="/product,${discount.target_option.product_id}">${discount.target_option.product.title}</a>` + " " + postFix; // not used
          console.log("hint", hint);
          if (usedNum != 0) {
            let buyXgetYuses = discount.one_to_each_customer == 1 ? 1 : usedNum;// not used
            console.log("buyXgetYuses", buyXgetYuses);
            // $usedDiscountIds[] = $discount -> id;
            if (percentage == null) {
              console.log("percentage", percentage);
              // extra free ..
              let extraOption = discount.target_option;
              if (extraOption != null) {
                console.log("extraOption", extraOption);
                let extraAvailable = extraOption.product.continue_selling == 0 ? extraOption.quantity - (parseInt(extraOption.sku)) : extraOption.quantity;
                console.log("extraAvailable", extraAvailable);
                let extraQuantity = amount > extraAvailable ? extraAvailable : amount;// not used
                console.log("extraQuantity", extraQuantity);
                let extraAvailablity = amount > extraAvailable ? false : true;// not used
                console.log("extraAvailablity", extraAvailablity);
                let extraOptionId = discount.product_option_id;// not used
                console.log("extraOptionId", extraOptionId);
              }
            } else {
              // extra with discount ..
              criticalQuant = amount;
              console.log("criticalQuant", criticalQuant);
              criticalPercn += percentage;
              console.log("criticalPercn", criticalPercn);
              nonCriticalQuant -= criticalQuant
              console.log("nonCriticalQuant", nonCriticalQuant);
            }
          }
        }
      }

      if (type === "percentage") {
        console.log("discount->type", type);
        getPercentage = discount.get_percentage
        usedNum = this.quantity <= discount.used_num ? this.quantity : discount.used_num;
        console.log("usedNum", usedNum);
        toContinue = this.quantity >= discount.min_quantity ? (this.money >= discount.min_cost ? true : false) : false;
        console.log("toContinue", toContinue);
        const one_to_each_customer: number = discount.one_to_each_customer;
        const get_percentage: number = discount.get_percentage
        if (usedNum != 0 && toContinue == true) {
          // $usedDiscountIds[] = $discount -> id;
          let percentageUses = one_to_each_customer == 1 ? 1 : usedNum;
          console.log("percentageUses", percentageUses);
          
          percentageDiscount +=  percentageUses * get_percentage * priceOfOption
          // criticalPercn += get_percentage * 100;
          // console.log("criticalPercn", criticalPercn);
          // console.log("nonCriticalPercn befor", nonCriticalPercn);
          // nonCriticalPercn += get_percentage * 100;
          // console.log("nonCriticalPercn", nonCriticalPercn);
        }

      }
      else if (type === "fixed") {
        console.log("discount->type", type);
        usedNum = this.quantity <= discount.used_num ? this.quantity : discount.used_num;
        console.log("usedNum", usedNum);

        toContinue = this.quantity >= discount.min_quantity ? (this.money >= discount.min_cost ? true : false) : false;
        console.log("toContinue", toContinue);
        if (usedNum != 0 && toContinue == true) {
          // $usedDiscountIds[] = $discount -> id;
          let fixedUses = discount.one_to_each_customer == 1 ? 1 : usedNum;
          console.log("fixedUses", fixedUses);
          extraMoney = fixedUses * discount.extra_money;
          console.log("extraMoney", extraMoney);
        }
      }
    })
    console.log("nonCriticalQuant,", nonCriticalQuant);

    let newPrice = nonCriticalQuant * priceOfOption;
    console.log("newPrice,", newPrice);
    if (nonCriticalPercn > 0) {
      newPrice = newPrice - ((newPrice * nonCriticalPercn) / 100);
    }


    let addForCritical = criticalQuant * priceOfOption;
    console.log("addForCritical,", addForCritical);
    console.log("criticalPercn,", criticalPercn);
    if (criticalPercn > 0) {
      addForCritical = addForCritical - ((addForCritical * criticalPercn) / 100);
    }
    console.log("addForCritical", addForCritical);
    console.log("this.priceAfterDiscount", newPrice);

    newPrice += addForCritical;
    console.log("this.priceAfterDiscount AFTER this.priceAfterDiscount += addForCritical", newPrice);

    newPrice -= extraMoney;
    newPrice -= percentageDiscount;
    newPrice = Math.round(newPrice/250.0)*250
    this.offPercn = criticalPercn===0?(getPercentage*100):criticalPercn;
    this.priceAfterDiscount = (newPrice != this.money) ? newPrice : 0
    console.log("this.priceAfterDiscount AFTER this.priceAfterDiscount -= extraMoney", newPrice);
    this.hint = hint;
  }
  // Add to cart
  public addToCart() {
    if(this.maxQuantity > 0 && this.product.is_active === true) {
      let price: number;
      if (this.priceAfterDiscount != 0) price = this.priceAfterDiscount
      else price = this.money
      const optionId: number = this.getOptionByVariantSelected[0].id
      this.submiting = true;
      this.spinner.show()
      this.cartService.addCart(optionId, this.quantity, price).subscribe(
        (result) => {
          this.submiting = false;
          console.log("result.AddCart", result);
          this.spinner.hide();
          this.snackBarOpen(result?.meta?.message);
  
          this.cartService.getMyCart().subscribe(
            (result) => {
              this.cartService.setMyCartInArrayObservable(result);
            })
  
        },
        (error) => {
          console.log(error);
          
          this.submiting = false;
          this.spinner.hide();
          this.snackBarOpen(error?.meta?.message);
        }
      );
    }
    else {
      this.snackBarOpen(this.translationMessages.max_quantity_is_zero);
    }
  }

  toggleMyWishlist(id: number) {
    if(this.product.is_active === true) {
      this.spinner.show();
      this.wishlistService.toggleMyWishlist(id).subscribe(
        (result) => {
          this.spinner.hide();
          console.log("result.getMyWishlist", result);
          this.snackBarOpen(result.meta.message);
          if (result.added === true)
            this.addedToMyWishList = 1; // 1 : mean clicked toggleMywishlist and added = true
          else if (result.added === false)
            this.addedToMyWishList = 2; // 2 : mean clicked toggleMywishlist and added = false
          console.log(" this.addedToMyWishList", this.addedToMyWishList);
  
        },
        (error) => {
          this.spinner.hide();
          this.snackBarOpen(error?.meta?.message);
        }
      );
    }
  }
  snackBarOpen(msg: string) {
    this._snackBar.open(msg, 'Cancel', {
      duration: 3000,
      horizontalPosition: 'center',
      verticalPosition: "top",
    });
  }

  public onMouseMove(e) {
    if (window.innerWidth >= 1280) {
      var image, offsetX, offsetY, x, y, zoomer;
      image = e.currentTarget;
      offsetX = e.offsetX;
      offsetY = e.offsetY;
      x = offsetX / image.offsetWidth * 100;
      y = offsetY / image.offsetHeight * 100;
      zoomer = this.zoomViewer.nativeElement.children[0];
      if (zoomer) {
        zoomer.style.backgroundPosition = x + '% ' + y + '%';
        zoomer.style.display = "block";
        zoomer.style.height = image.height + 'px';
        zoomer.style.width = image.width + 'px';
      }
    }
  }

  public onMouseLeave(event) {
    this.zoomViewer.nativeElement.children[0].style.display = "none";
  }
  @HostListener('keydown', ['$event'])
  changeQuantity(event: KeyboardEvent) {
    event.preventDefault();

  }
  // public openZoomViewer() {
  //   this.dialog.open(ProductZoomComponent, {
  //     data: this.zoomImage,
  //     panelClass: 'zoom-dialog'
  //   });
  // }
}


