import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'collection',
  templateUrl: './collection.component.html',
  styleUrls: ['./collection.component.sass']
})
export class CollectionComponent implements OnInit {

  @Input("product") product: any;
  constructor() { }

  ngOnInit() {
  }

}
