import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// console.log = function(){}
console.log(`%c Looklok Under Development %cBy Developers: Abdullah Batu `, 'background: #252525; color: #E36049; font-size:22px;font-weight:900;','background: #252525; color: #66d9ef; font-size:22px;font-weight:900;');
if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
