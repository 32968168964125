import { OTokenInfo, OUserInfo, OMeta, OSection, OCategories, OCollectionOrDiscounts, OPagination, OTags, OOptions, OVariants, ODiscounts, OProduct, OCartOption, OCity, OOrderItem, OOrderDetails, ODiscountsOption, OTargetOption, OWishlistItem } from './general';
import { elementAt } from 'rxjs/operators';

export class Login {
  tokenInfo: OTokenInfo;
  userInfo: OUserInfo;
  meta: OMeta;
  // public login: object
  constructor(response) {
    this.tokenInfo = new OTokenInfo(response)
    this.userInfo = new OUserInfo(response.data?.user)
    this.meta = new OMeta(response);
    // this.login = { ...this.meta.meta, ...this.tokenInfo.token_info, ...this.userInfo.user_info }
  }

}


export interface ISection {
  title: string;
  items: OSection;
  rail_id:number;
  filter:number
}
export class Home {
  sections: Array<ISection> = [];
  categories: OCategories;
  discounts: OCollectionOrDiscounts;
  collection: OCollectionOrDiscounts;

  meta: OMeta;
  constructor(response) {
    response?.data?.sections.forEach(element => {
      this.sections.push({
        title: element.title,
        items: element.items?.map(element => new OSection(element).section),
        rail_id:element.rail_id,
        filter:element.filter
      })
    }),
    this.categories = response?.data?.categories?.map(element => new OCategories(element).categories);
    this.discounts = response?.data?.discounts?.map(element => new OCollectionOrDiscounts(element).collectionOrDiscounts);
    this.collection = response?.data?.collection?.map(element => new OCollectionOrDiscounts(element).collectionOrDiscounts);
    this.meta = new OMeta(response)
  }
}
export class Product {
  product = {
    items: undefined,
  }
  pagination: OPagination
  meta: OMeta;
  constructor(response) {
    this.product = {
      items: response.data.items?.map(element => new OSection(element).section),
    };
    this.pagination = new OPagination(response.data)
    this.meta = new OMeta(response)
  }
}

export class Tags {
  tags = {
    items: undefined,
  }
  meta: OMeta;
  constructor(response) {
    this.tags = {
      items: response.data?.map(element => new OTags(element).tags),
    };
    this.meta = new OMeta(response)
  }
}

export class Categories {
  categories;
  meta: OMeta;
  constructor(response) {
    this.categories = response.data
    this.meta = new OMeta(response)
  }
}

export class ProductDetails {
  product: OProduct
  after_discount_flag:boolean;
  after_discount_val:number;

  images: any[];
  options: OOptions[];
  variants: OVariants[];
  discounts: ODiscounts[];
  discount:any;
  has_discount: boolean;
  similar_products: OSection;
  discount_hint:string;
  discount_hints:any;
  meta: OMeta;
  constructor(response) {
    this.product = response?.data?.product;
    this.after_discount_flag = response?.data?.after_discount_flag;
    this.after_discount_val = response?.data?.after_discount_val;
    this.discount = response?.data?.discount;
    this.images = response?.data?.images;
    this.options = response?.data?.options?.map(element => new OOptions(element).options)
    this.variants = response?.data?.variants?.map(element => new OVariants(element).variants)
    this.discounts = response?.data?.discounts?.map(element => new ODiscounts(element).discounts)
    this.has_discount = response?.data?.has_discount;
    this.similar_products = response?.data?.similar_products?.map(element => new OSection(element).section);
    this.discount_hint = response?.data?.discount_hint;
    this.discount_hints = response?.data?.discount_hints;
    this.meta = new OMeta(response)
  }
}
export class MyCart {
  id: number;
  total_price: number
  options: OCartOption[];
  meta: OMeta;
  constructor(response) {
    this.id = response?.data?.id;
    this.total_price = response?.data?.total_price;
    this.options = response?.data?.options?.map(element => new OCartOption(element).options)
    this.meta = new OMeta(response)
  }
}
export class Price {
  base_price: number;
  new_price: number;
  extra_quantity: any;
  quantity: number;
  critical_q: number;
  critical_p: number;
  non_crt_q: number;
  non_crt_p: number;
  meta: OMeta;
  constructor(response) {
    this.base_price = response?.data?.base_price;
    this.new_price = response?.data?.new_price;
    this.extra_quantity = response?.data?.extra_quantity;
    this.quantity = response?.data?.quantity;
    this.critical_q = response?.data?.critical_q;
    this.critical_p = response?.data?.critical_p;
    this.non_crt_q = response?.data?.non_crt_q;
    this.meta = new OMeta(response)
  }
}
export class Checkout {
  subtotal: number;
  discount: string;
  shipping: number;
  total: number;
  ip_in_iraq:boolean;
  user: any;
  meta: OMeta;
  constructor(response) {
    this.subtotal = response?.data?.subtotal;
    this.discount = response?.data?.discount;
    this.shipping = response?.data?.shipping;
    this.total = response?.data?.total;
    this.ip_in_iraq = response?.date?.ip_in_iraq;
    this.user = new OUserInfo(response.data?.user)
    this.meta = new OMeta(response)
  }
}
export class CheckoutConfirm {
  id: number;
  redirect_link:string;
  meta: OMeta;
  constructor(response) {
    this.id = response?.data?.id;
    this.redirect_link = response?.data?.redirect_link;
    this.meta = new OMeta(response)
  }
}
export class Cities {
  data: OCity[];
  meta: OMeta;
  constructor(response) {
    this.data = response?.data?.map(element => new OCity(element).city)
    this.meta = new OMeta(response)
  }
}
export class PhoneVerified {
  meta: OMeta;
  constructor(response) {
    this.meta = new OMeta(response)
  }
}
export class Order {
  items: OOrderItem[];
  pagination: OPagination;
  meta: OMeta;
  constructor(response) {
    this.items = response?.data?.items?.map(element => new OOrderItem(element).item)
    this.pagination = new OPagination(response?.data)
    this.meta = new OMeta(response)
  }
}
export class OrderDetails {
  data: any;
  meta: OMeta;
  constructor(response) {
    this.data = new OOrderDetails(response?.data).orderDetails;
    this.meta = new OMeta(response)
  }
}
export class DiscountDetails {
  discount: any;
  productOptionsItems: OTargetOption[]
  pagination: OPagination;
  meta: OMeta;
  constructor(response) {
    this.discount = new ODiscountsOption(response?.data?.discount).discounts
    this.productOptionsItems = response?.data?.product_options?.items?.map(element => new OTargetOption(element).target_option)
    this.pagination = new OPagination(response?.data?.product_options);
    this.meta = new OMeta(response)
  }
}
export class UserInfo {
  user: any;
  meta: OMeta;
  constructor(response) {
    this.user = new OUserInfo(response?.data)
    this.meta = new OMeta(response)
  }
}
export class Settings {

  key: string;
  content: string;
  meta: OMeta;
  constructor(response) {
    this.key = response?.data?.key;
    this.content = response?.data?.content;
    this.meta = new OMeta(response)
  }
}

export class MyWishlist {
  items: OWishlistItem[];
  pagination: OPagination;
  meta: OMeta;
  constructor(response) {
    this.items = response?.data?.items?.map(element => new OWishlistItem(element).item)
    this.pagination = new OPagination(response?.data)
    this.meta = new OMeta(response)
  }
}
export class WishlistToggle {
  added: boolean;
  meta: OMeta;
  constructor(response) {
    this.added = response?.data?.added;
    this.meta = new OMeta(response)
  }
}

export class Collection {
  items: OCollectionOrDiscounts[];
  pagination: OPagination;
  meta: OMeta;
  constructor(response) {
    this.items = response?.data?.items?.map(element => new OCollectionOrDiscounts(element).collectionOrDiscounts)
    this.pagination = new OPagination(response?.data)
    this.meta = new OMeta(response)
  }
}
export class ProductCollection {

  id: number;
  title: string;
  description: string;
  image: string;

  products: OSection[];

  pagination: OPagination
  meta: OMeta;
  constructor(response) {
    this.id = response?.data?.id;
    this.title = response?.data?.title;
    this.description = response?.data?.description;
    this.image = response?.data?.banner;

    this.pagination = new OPagination(response.data.products);
    this.meta = new OMeta(response)
    this.products = response?.data?.products?.items?.map(element => new OSection(element).section);
  }
}
export class FilterAdvance {
  categories: OCategories[];
  max_price: string;
  min_price: string;
  collections: OCollectionOrDiscounts[];
  meta: OMeta;
  constructor(response) {
    this.categories = response?.data?.categories?.map(element => new OCategories(element).categories)
    this.max_price = response?.data?.max_price;
    this.min_price = response?.data?.min_price;
    this.collections = response?.data?.collections?.map(element => new OCollectionOrDiscounts(element).collectionOrDiscounts)
    this.meta = new OMeta(response)

  }
}
