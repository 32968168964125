import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthServices } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { CartService } from 'src/app/services/cart.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Meta } from '@angular/platform-browser';
import { Home } from 'src/app/modals/response';
import { select, Store } from '@ngrx/store';
import * as HomeStore from '../../../store/home/home.reducer';
import * as HomeSelector from '../../../store/home/home.selectors';
import { loadHome } from 'src/app/store/home/home.action';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { AppState } from 'src/app/store';

@Component({
  selector: 'app-home-two',
  templateUrl: './home-two.component.html',
  styleUrls: ['./home-two.component.sass']
})
export class HomeTwoComponent implements OnInit {
  sections: any;
  categories: any;
  discounts: any;
  collection: any;
  currency: string = ""
  userInfo$: Observable<any>;
  railActived:any;

  isLoading$: Observable<boolean>;
  isFirstLoad$: Observable<boolean>;
  error$: Observable<any>;
  home$: Observable<Home>;
  constructor(private spinner: NgxSpinnerService, 
    private homeService: HomeService, 
    private cartService:CartService,
    private titleService: Title,
    private meta: Meta,
    private translate: TranslateService,
   
    private authService: AuthServices) {
      // this.store.dispatch(loadHome())
      // this.home$ = this.store.pipe(select(HomeSelector.getHome));
      // this.isLoading$ = this.store.pipe(select(HomeSelector.getHomeLoading));
      // this.isFirstLoad$ = this.store.pipe(select(HomeSelector.getFirstLoad));
      // this.error$ = this.store.pipe(select(HomeSelector.getError));
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.home);
        // this.meta.updateTag({ name: 'description', content: value.description })
      })
      this.userInfo$ = this.authService.isAuth;
    
    // if (this.authService.isLogined) {
    //   this.userInfo$.subscribe(user =>
    //     this.currency = user.user_info?.main_currency
    //   )
    // }
    // // else {
    // //   this.currency = result?.meta?.main_currency;
    // // }
  }

  ngOnInit() {
    if (this.authService.isLogined === true) {
      this.cartService.getMyCartSubscribe()
    }
    else {
      this.cartService.setMyCartInArrayObservable(null);
    }
    this.getHome();
    // this.homeService.getTest().subscribe(
    //   (result) => {

    //   }
    // );
  }
  
  getHome() {
    this.spinner.show()
    this.homeService.getHome().subscribe(
      (result) => {
        this.spinner.hide()
        console.log("result Home is ", result);
        // this.baseService.spinnerLoading("off");
        this.sections = result.sections;
        this.categories = result.categories;
        this.discounts = result.discounts;
        this.collection = result.collection;
        console.log("this.sections", this.sections);
          // if (this.authService.isLogined) {
          //   this.userInfo$.subscribe(user =>
          //     this.currency = user.user_info?.main_currency
          //   )
          // }
          // else {
            this.currency = result?.meta?.main_currency;
          // }
        // this.selectedTabChange({index:0})
      },
      (error) => {
        this.spinner.hide()
        console.log("error Home  is ", error);
        // this.baseService.spinnerLoading("off");
        // this.baseService.alert(error, "error")

      }
    );
  }
  selectedTabChange(event:any) {
    this.railActived = this.sections[event.index];
    console.log("TAB IS CLICKEd",this.railActived);

  }

}
