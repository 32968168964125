import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { CustomHeaderService } from './custom-header.service';
import { map, catchError } from 'rxjs/operators';
import { config } from './config';
import { Home, DiscountDetails } from '../modals/response';
import { AuthServices } from './auth.service';
 import { decryptAPI } from './security.provider';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  constructor(private http: HttpClient, 
     
    private headerService: CustomHeaderService, private authService: AuthServices) { }
  public getHome(): Observable<Home> {
    console.log("GGDHKJSDHFKJDHKFJDSHKJFHDSKJFHSDKJH");
    
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    const params = new HttpParams().set("parent_id", "0");
    // return this.http.get<Home>(config.ApiUrl + "home/rail", { headers, params })
    return this.http.get<string>(config.ApiUrl + "home/rail", { headers, params }).pipe(
      map((data) => {
        return new Home(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getDiscountDetails(id: number, page = "none", limit = "none"): Observable<DiscountDetails> {
    let headers;
    if (this.authService.isLogined === true) {
      headers = new HttpHeaders(this.headerService.basicHeaders())
    }
    else {
      headers = new HttpHeaders(this.headerService.basicHeaders(true))
    }
    let params = new HttpParams();
    if (page != "none" && page) {
      params = params.append("page", page.toString())
    }
    if (limit != "none" && limit) {
      params = params.append("limit", limit.toString())
    }
    return this.http.get<string>(config.ApiUrl + "discount/" + id, { headers, params }).pipe(
      map((data) => {
        return new DiscountDetails(decryptAPI(data));
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
  getTest(): Observable<any> {
    let headers;
    headers = new HttpHeaders(this.headerService.basicHeaders())
    return this.http.get<string>(config.ApiUrl + "test-encrypt", { headers }).pipe(
      map((data) => {
        return decryptAPI(data);
      }), catchError(error => {
        return throwError(decryptAPI(error));
      })
    )
  }
}
