import { Component, OnInit } from '@angular/core';
import { CategorayService } from 'src/app/services/categoray.service';
import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-two',
  templateUrl: './footer-two.component.html',
  styleUrls: ['./footer-two.component.sass']
})
export class FooterTwoComponent implements OnInit {
  categories: any[] = [];
  constructor(private categoryService: CategorayService, private translate: TranslateService) { }

  ngOnInit() {
    this.translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.getCategory()
    });
  }
  getCategory() {
    this.categoryService.getCategories(undefined).subscribe(
      (result) => {
        console.log("result.category", result.categories);
        this.categories = result.categories;
      },
      (error) => {
      }
    );
  }
}
