export const config = {
  // ApiUrl: "https://looklok.ecommerce.apps.codersdc.com/api/web/",
  // ApiUrl: "http://192.168.88.42/looklok/public/api/web/",
  // ApiUrl: "http://37.98.230.67/api/web/",
  ApiUrl: 'https://looklokadmin.codersdc.net/api/web/',
  // ApiUrl: "https://backend.looklok.com/api/web/",
  // ApiUrl: "https://looklokadmin.codersdc.net/api/web/",
  // decryptKey:"c0IQlTkHGYneyaDeb9sy+rqbRbGfAUGSCewc9FbMhAA=" // ApiUrl: "https://looklokadmin.codersdc.net/api/web/",
  decryptKey: 'c0IQlTkHGYneyaDeb9sy+rqbRbGfAUGSCewc9FbMhAA=',
  // decryptKey:"y9C2185MpiBXyZ/M/bJFzxUENTDmnw7YeX0V2PbyR2g=" //ApiUrl: "https://backend.looklok.com/api/web/",

};
