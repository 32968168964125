import { Component, NgZone, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { ProductService } from 'src/app/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CategorayService } from 'src/app/services/categoray.service';
import { AuthServices } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.scss']
})
export class ProductLeftSidebarComponent implements OnInit , OnDestroy {


/**For Products Viewer Style**/
public my_row:string;
public myCol_md_60:string;
public myCol_md_40:string;
public myImg:string;
public noMovement: boolean=false;

/**For Products Viewer Style End**/
  public sidenavOpen: boolean = true;
  public animation: any;   // Animation
  public sortByOrder: string = '';   // sorting

  public viewType: string = 'grid';
  public viewCol: number = 25;

  public colors: any[] = [];

  category: any[] = [];
  collections: any[] = [];
  tags: any[] = [];
  min_price: number;
  max_price: number;
  private itemsSubject;
  items$: Observable<Array<any>>;
  // filter = "date";
  // dir = "desc";
  sorting="date-desc";
  selectSorting = "date-desc"
  subcategory_id;
  category_id;
  collection_id;
  tag_ids: any[] = [];
  price: any[] = [];
  page = 1;
  total_pages;
  limit = 20;
  submiting: boolean = false;
  currency: string = "";
  rail_id:string="none";
  userInfo$: Observable<any>;
  productsSubscribtion : Subscription;
  constructor(private productService: ProductService,
    private titleService: Title,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router : Router,
    private authService: AuthServices,
    private spinner: NgxSpinnerService) {
      this.itemsSubject = new BehaviorSubject<Array<any>>([]);
      this.items$ = this.itemsSubject.asObservable();
      this.translate.get('titles').subscribe(value=> {
        this.titleService.setTitle(value.products);
      })
      this.userInfo$ = this.authService.isAuth;

  }
  ngOnDestroy(): void {
    this.productsSubscribtion.unsubscribe();
    // this.itemsSubject.next([]);
    // this.itemsSubject.complate([])
  }
  ngOnInit() {

    this.itemsSubject.next([]);
    this.getFilterAdvance()
    // this.itemsSubject = new BehaviorSubject<Array<any>>([]);
    // this.items$ = this.itemsSubject.asObservable();
    this.getTags();
    console.log("category_id", this.category_id);
      this.route.params.subscribe(
        (params: Params) => {

          const category = params['category'];
          if (category !== "all") {
            this.category_id = Number(category);
            // this.onChangeSorting("most_view-none")
            this.getProduct();
          }
        }
      )
      this.route.queryParamMap.subscribe((params) => {
        // if (params.get("filter") !== null) {
        //   this.filter = params.get("filter").toString();
        // }

        if (params.get("rail_id") !== null) {
          this.rail_id = params.get("rail_id");

        }
        if (params.get("sorting") !== null) {
          this.sorting = params.get("sorting").toString();
          this.selectSorting = this.sorting;
        }
        else {
          // this.sorting = "date-desc"
        }
        this.getProduct();
       
      });
      // this.getProduct();

  }
  ngAfterViewChecked(): void {
    //Called after every check of the component's view. Applies to components only.
    //Add 'implements AfterViewChecked' to the class.
    this.selectSorting = this.sorting
    
  }


  
  getProduct() {
    this.itemsSubject.next([]);

    this.submiting = true;
    this.spinner.show();
    this.productsSubscribtion = this.productService.getProduct(this.sorting.split("-")[0], this.subcategory_id, this.category_id,
      this.collection_id, this.price, this.page.toString(), this.limit.toString(), undefined, this.sorting.split("-")[1],this.rail_id).subscribe(
        (result) => {
          this.submiting = false;
          this.itemsSubject.next(result.product.items);
          console.log("this.items$", this.items$);

          console.log("result.product", result.product);
          this.total_pages = result.pagination.total_pages;
          this.page = result.pagination.page;
          this.spinner.hide();
          // if (this.authService.isLogined) {
          //   this.userInfo$.subscribe(user =>
          //     this.currency = user.user_info?.main_currency
          //   )
          // }
          // else {
            this.currency = result?.meta?.main_currency;
          // }

        },
        (error) => {
          this.submiting = false;
        }
      );

  }
  getProductForPriceFilter() {
    this.submiting = true;
    this.productService.getProduct(this.sorting.split("-")[0], this.subcategory_id, this.category_id,
      this.collection_id, this.price, this.page.toString(), this.limit.toString(), undefined, this.sorting.split("-")[1]).subscribe(
        (result) => {
          this.submiting = false;

          this.itemsSubject.next(result.product.items);
          console.log("this.items$", this.items$);

          console.log("result.product", result.product);
          this.total_pages = result.pagination.total_pages;
          this.page = result.pagination.page;
          this.spinner.hide();
          // if (this.authService.isLogined) {
          //   this.userInfo$.subscribe(user =>
          //     this.currency = user.user_info?.main_currency
          //   )
          // }
          // else {
            this.currency = result?.meta?.main_currency;
          // }
        },
        (error) => {
          this.submiting = false;
        }
      );

  }
  getTags() {
    this.productService.getTags().subscribe(
      (result) => {
        console.log("result.tags", result.tags);
        this.tags = result.tags.items;
      },
      (error) => {
      }
    );
  }
  changePage(page) {
    this.page = page;
    console.log("changePage(page)",this.page);
    this.getProduct();
    
  }
  // sorting
  public onChangeSorting(val) {
    this.selectSorting = val;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        sorting:val
      },
      queryParamsHandling: 'merge',
    });
   
    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }

  // Update price filter
  public filterByPrices(price: any) {
    if (this.submiting === false) {
      console.log("price", price);
      let p = []
      p.push(price.priceFrom);
      p.push(price.priceTo)
      this.price = p
      console.log("===========>");
      console.log(this.price);
      this.getProductForPriceFilter()
    }
  }


  // Update tags filter
  updateTagFilters(tagsId: number, index: number) {
    if (this.submiting === false) {
      let indexTagsId = this.tag_ids.findIndex(val => val == tagsId)
      if (indexTagsId !== -1) {
        this.tag_ids.splice(indexTagsId, 1)
      }
      else {
        this.tag_ids.push(tagsId)
      }
      this.getProduct()
    }

    this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }
  tagsIdIsActive(tagId): boolean {
    let res: boolean = false;
    if (this.tag_ids.indexOf(tagId) !== -1) res = true;
    else res = false;
    return res
  }

  getFilterAdvance() {
    this.productService.getFilterAdvance().subscribe(
      (result) => {
        console.log("result.FilterAdvance", result);
        this.category = result.categories;
        this.min_price = Number(result.min_price);
        this.max_price = Number(result.max_price)
        this.collections = result.collections
      },
      (error) => {
      }
    );
  }
  filterByCategory(id) {
    console.log("updateCategoryFilters(id)", id);
    this.category_id = id;
    this.subcategory_id = undefined;
    this.getProduct();

  }
  filterByCollection(id) {
    this.collection_id = id;
    this.getProduct();
  }
  updateSubcategoryFilters(id) {
    console.log("updateSubcategoryFilters(id)", id);
    this.subcategory_id = id;
    this.category_id = undefined
    this.getProduct();
  }
  changeViewType(viewType, viewCol) {
    this.viewType = viewType;
    this.viewCol = viewCol;
    if(viewCol=='100')
    {
      this.my_row="myRow";
      this.myCol_md_60="myCol-md-60";
      this.myCol_md_40="myCol-md-40";
      this.myImg="myImg";
      this.noMovement=true;
    }
    else{
      this.my_row=" ";
      this.myCol_md_60=" ";
      this.myCol_md_40=" ";
      this.myImg=" ";
      this.noMovement=false;

    }
  }
  // Animation Effect fadeIn
  public fadeIn() {
    this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
    this.animation = 'fadeOut';
  }
}
