import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CartService } from 'src/app/services/cart.service';
import { CartComponent } from '../../pages/cart/cart.component';
import { AuthServices } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CheckoutService } from 'src/app/services/checkout.service';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-shopping-widgets-two',
  templateUrl: './shopping-widgets-two.component.html',
  styleUrls: ['./shopping-widgets-two.component.sass']
})
export class ShoppingWidgetsTwoComponent extends CartComponent implements OnInit {


  public sidenavMenuItems: Array<any>;

  @Input() shoppingCartItems: any;
  @ViewChild(CartComponent) cartComponent: CartComponent;
  constructor(public cartService: CartService,
    public checkoutService: CheckoutService,
    public spinner: NgxSpinnerService,
    public authService: AuthServices,
    public _snackBar: MatSnackBar,
    public dialog: MatDialog,
    public titleService: Title,
    public translate: TranslateService,
    public router: Router) {
    super(cartService, checkoutService, spinner, authService, _snackBar, dialog, titleService, translate, router)

  }


  ngOnInit() {
    if (this.authService.isLogined === true) {
      this.cartService.getMyCartSubscribe()
    }
    else {
      this.cartService.setMyCartInArrayObservable(null)
    }
  }
}
